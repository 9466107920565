<div class="breadcrumbs">
  <div class="path">
    <a href routerLink="/dashboard">Home</a>
    <ng-container *ngFor="let item of breadcrumbs; let last = last;">
      <span *ngIf="!last">
        <span class="arrow"></span>
        <span><a href [routerLink]="[item.url]">{{ item.title }}</a></span>
      </span>
      <span *ngIf="last">
        <span class="arrow"></span>
        <strong class="current">{{ item.title }}</strong>
      </span>
    </ng-container>
  </div>
</div>