import { Component, OnInit } from "@angular/core";
function getWindow(): any {
  return window;
}
@Component({
  selector: "app-topbar-support",
  styleUrls: ["style.scss"],
  template: `
     <div>
       <!-- <a href="https://mcttechnology.zendesk.com/hc/en-us" target="_blank">
        Agency Support (Zendesk)</a><i class="ml-1 fa fa-external-link"></i> -->
  <div nz-dropdown nzTrigger="click" nzPlacement="bottomLeft" class="dropdown" [nzDropdownMenu]="menu">
    <div class="flag">
    <!-- <i nz-icon nzType="info-circle" nzTheme="outline"></i> -->
    <i class="icon fa fa-h-square  fa-2x"></i>
      <span class="text-uppercase ml-1">Customer Support</span>
    </div>
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item >
        <a href="https://mcttechnology.zendesk.com/agent/dashboard" target="_blank">
        Agency Support (Zendesk)</a><i class="ml-1 fa fa-external-link"></i>
      </li>
      <li nz-menu-item>
      <a routerLink="/ticket/channel/0-mct-parent-ticket" target="_blank">
      Parent Support</a><i class="ml-1 fa fa-external-link"></i>
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item *appPermission="['app.ticketportal.channel']">
      <a [routerLink]="['/ticket/channel']">
      Ticket Potal Admin</a>
      </li>
    </ul>
  </nz-dropdown-menu>
</div>
  `
})
export class TopbarSupportComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}