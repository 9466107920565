import DxDataGrid from "devextreme/ui/data_grid";
import * as moment from "moment";
import * as _ from "lodash";
import { FluentDxToolBar } from "./dxButtons";
import { DxoButtonOptions } from "devextreme-angular/ui/nested/base/button-options";

export const defaultSmartListConfiguration: any = {
  allowColumnReordering: true,
  allowColumnResizing: true,
  cellHintEnabled: true,
  columnAutoWidth: true,
  columnChooser: {
    allowSearch: true,
    // emptyPanelText?: string;
    enabled: false,
    height: 500,
    mode: "select"
    // searchTimeout?: number;
    // title?: string;
    // width?: number;
  },
  columnFixing: {
    enabled: true
    // texts?: {
    //     fix?: string;
    //     leftPosition?: string;
    //     rightPosition?: string;
    //     unfix?: string;
    // };
  },
  export: {
    allowExportSelectedData: true,
    // customizeExcelCell?: Function;
    enabled: false
    // excelFilterEnabled?: boolean;
    // excelWrapTextEnabled?: boolean;
    // fileName?: string;
    // ignoreExcelErrors?: boolean;
    // proxyUrl?: string;
    // texts?: {
    //     exportAll?: string;
    //     exportSelectedRows?: string;
    //     exportTo?: string;
    // };
  },
  pager: {
    allowedPageSizes: [50, 100, 200],
    infoText: "Page {0} / {1} ( Total: {2} items )",
    showInfo: true,
    // showNavigationButtons?: boolean;
    showPageSizeSelector: true,
    visible: true
  },
  paging: {
    // enabled?: boolean;
    // pageIndex: 0,
    pageSize: 50
  },
  remoteOperations: {
    filtering: true,
    // grouping?: boolean;
    // groupPaging?: boolean;
    paging: true,
    sorting: true
    // summary?: boolean;
  },
  selection: {
    // allowSelectAll: true,
    // deferred?: boolean;
    mode: "multiple",
    selectAllMode: "page"
    // showCheckBoxesMode: 'onClick'
  },
  scrolling: {
    // columnRenderingMode?: string;
    // mode?: string;
    // preloadEnabled?: boolean;
    // renderAsync?: boolean;
    // rowRenderingMode?: string;
    // scrollByContent?: boolean;
    // scrollByThumb?: boolean;
    //  showScrollbar?: string;'"never" | "always" | "onHover" | "onScroll"'
    useNative: true
  },
  sorting: {
    mode: "multiple"
  },
  showBorders: true,
  showColumnLines: true,
  rowAlternationEnabled: true,
  noDataText: "No Data",
  toolbar: { items: [] }
};
export class dxDataGridService {
  public grid: DxDataGrid;
  public gridCode: string;

  constructor(gridInstance: DxDataGrid) {
    this.grid = gridInstance;
  }

  public Option(option) {
    const allOptions = _.merge(option, defaultSmartListConfiguration);
    this.grid.option(allOptions);
    return this;
  }

  public AutoHeight(minHeight = 200, top = 175) {
    this.grid.option("height", this.autoHeight(minHeight, top));
    return this;
  }

  public ShowButtons(buttons: any[]) {
    // if (!this.grid.option("toolbar").items) {
    //   this.grid.option("toolbar").items = []
    // }
    buttons.forEach(btn => this.grid.option("toolbar").items.push(btn));
    return this;
  }

  public ShowSearch(width = 200, placeholder = "Search...") {
    this.ShowButtons([{ name: "searchPanel" }]);
    // this.grid.option("toolbar").items.push({ name: "searchPanel" });
    this.grid.option("searchPanel", {
      visible: true,
      width: width,
      placeholder: placeholder
    });
    return this;
  }
  public ShowRefresh() {
    const vm = this;
    const btn: any = FluentDxToolBar.Button()
      .Icon("refresh")
      .After()
      .Click(e => vm.grid.refresh());

    // this.grid.option("toolbar").items.push(btn);
    this.ShowButtons([btn]);
    return this;
  }
  public ShowColumnChooser() {
    this.ShowButtons([{ name: "columnChooserButton" }]);
    // this.grid.option("toolbar").items.push({ name: "columnChooserButton" });
    this.grid.option("columnChooser", { enabled: true });
    return this;
  }

  public ShowFilter() {
    this.grid.option("filterRow", { visible: true });
    return this;
  }

  public ShowFilterPanel() {
    this.grid.option("filterPanel", { visible: true });
    return this;
  }

  public ShowExport(allowSelectedData = true) {
    this.ShowButtons([{
      name: "exportButton",
      options: {
        dropDownOptions: {
          width: 250
        }
      }
    }]);
    // this.grid.option("toolbar").items.push({
    //   name: "exportButton",
    //   options: {
    //     dropDownOptions: {
    //       width: 200
    //     }
    //   }
    // });
    this.grid.option("export", {
      allowExportSelectedData: allowSelectedData,
      enabled: true
    });
    return this;
  }

  public EnableStoring(code: string) {
    this.grid.option("stateStoring", {
      enabled: true,
      storageKey: code,
      type: "sessionStorage"
    });
  }

  public initGrid(option: "remote" | "local" = "remote") {
    this.Option(null);
    this.grid.option("height", this.autoHeight);
    if (option === "local") {
      this.grid.option("remoteOperations", {
        filtering: false,
        // grouping?: boolean;
        // groupPaging?: boolean;
        paging: false,
        sorting: false
        // summary?: boolean;
      });
    } else {
      this.grid.option("remoteOperations", {
        filtering: true,
        // grouping?: boolean;
        // groupPaging?: boolean;
        paging: true,
        sorting: true
        // summary?: boolean;
      });
    }
  }

  public setHeight(height, top = 0): void {
    if (top == 0) {
      this.grid.option("height", height);
    } else {
      this.grid.option("height", this.autoHeight(height, top));
    }
  }

  public search() {
    this.grid.refresh();
  }

  public openColumnChooser() {
    this.grid.showColumnChooser();
  }

  public searchByText(text) {
    this.grid.searchByText(text);
  }

  public getSelectData() {
    return this.grid.getSelectedRowsData();
  }

  private autoHeight(min = 200, top = 175) {
    const h = window.innerHeight - top;
    return h < min ? min : h;
  }

  private loadState() {
    const state = sessionStorage.getItem("viewerstate_of_" + this.gridCode);
    if (state) {
      const gridState = JSON.parse(state);
      // this.options = gridState.viewerOptions;
      return gridState;
    }
  }

  private saveState(state) {
    // state.viewerOptions = this.options;
    sessionStorage.setItem(
      "viewerstate_of_" + this.gridCode,
      JSON.stringify(state)
    );
  }

  public getTemplate(template, data) {
    return _.template(template)({ data });
    // return ToolService.getTemplate(template, data);
  }
}
