<div class="d-inline-block mr-4">
  <nz-input-group class="extInput" [nzPrefix]="prefixIconSearch">
    <input nz-input (focus)="setVisible()" placeholder="{{ 'topBar.typeToSearch' | translate }}" style="width: 200px" />
  </nz-input-group>
  <ng-template #prefixIconSearch>
    <i nz-icon nzType="search" class="extInputIcon" style="color: rgba(0,0,0,.25)" (click)="setVisible()"></i>
  </ng-template>
  <div class="livesearch" [ngClass]="{'livesearchVisible': showSearch}">
    <button class="close" type="button" (click)="setHidden()">
      <i class="fe fe-x"></i>
    </button>
    <div class="container-fluid">
      <div class="wrapper">
        Filters <nz-select
        [nzMaxTagCount]="3"
        [nzMaxTagPlaceholder]="tagPlaceHolder"
        nzMode="multiple"
        nzPlaceHolder="Please select"
        [nzDisabled]="true"
        [(ngModel)]="selectedValue">
        <nz-option *ngFor="let item of optionList" [nzLabel]="item.label" [nzValue]="item.value"></nz-option>
      </nz-select>
      <ng-template #tagPlaceHolder let-selectedList>and {{ selectedList.length }} more selected</ng-template>
          Name<input nz-input placeholder="Search name..." [(ngModel)]="nameSearch" class="text-input"/>
          ZIP Code or City<input nz-input placeholder="Search zip code or city..." [(ngModel)]="searchText" class="text-input"/>
          <br>
          <button id="showHideFilters" type="button" (click)="showHideFilters()">Add Filters</button>
          <button id="resetFilters" type="button" (click)="resetFilters()">Reset Filters</button>
          <br>
          <p class="toggle-hidden" hidden>
          Age <input nz-input placeholder="Search age..." [(ngModel)]="ageSearch" class="text-input"/>
          <br>
          Start Time <nz-time-picker [(ngModel)]="startTimeSearch" [nzDefaultOpenValue]="defaultOpenValue" nzFormat="HH:mm"></nz-time-picker>
          Availability <nz-radio-group [(ngModel)]="availabilitySearch">
            <label nz-radio nzValue="available">Available right now</label>
            <label nz-radio nzValue="availableLater">Available later</label>
            <label nz-radio nzValue="any">Any availability</label>
          </nz-radio-group>
          <br>
          End Time <nz-time-picker [(ngModel)]="endTimeSearch" nzFormat="HH:mm"></nz-time-picker>
          <br> 
          Program Type <nz-radio-group [(ngModel)]="searchDaycare">
            <label nz-radio nzValue="daycare">Daycare</label>
            <label nz-radio nzValue="preschool">Preschool</label>
            <label nz-radio nzValue="both">Both</label>
            <label nz-radio nzValue="closed">Permanently Closed</label>
          </nz-radio-group>
          <br>
          Calendar <nz-radio-group [(ngModel)]="calendarSearch">
            <label nz-radio nzValue="fullYearSummerCare">Full Year, Summer Care</label>
            <label nz-radio nzValue="schoolYear">School Year</label>
            <label nz-radio nzValue="summerOnly">Summer Only</label>
          </nz-radio-group>
          <br>
          Education <nz-select
            [nzMaxTagCount]="5"
            nzMode="multiple"
            nzShowSearch
            [(ngModel)]="educationSearch">
              <nz-option nzLabel="Academic Based" nzValue="academicBased"></nz-option>
              <nz-option nzLabel= "Arts-Based" nzValue="artsBased"></nz-option>
              <nz-option nzLabel= "BAKS" nzValue="baks"></nz-option>
              <nz-option nzLabel="Blended Curriculum" nzValue="blendedCurriculum"></nz-option>
              <nz-option nzLabel="Creative Curriculum" nzValue="creativeCurriculum"></nz-option>
              <nz-option nzLabel="Bilingual" nzValue="bilingual"></nz-option>
              <nz-option nzLabel="Emergent" nzValue="emergent"></nz-option>
              <nz-option nzLabel="Forest School" nzValue="forestSchool"></nz-option>
              <nz-option nzLabel="HighScope" nzValue="highScope"></nz-option>
              <nz-option nzLabel="Language Immersion" nzValue="languageImmersion"></nz-option>
              <nz-option nzLabel="Little Goose" nzValue="littleGoose"></nz-option>
              <nz-option nzLabel="Mixed Age" nzValue="mixedAge"></nz-option>
              <nz-option nzLabel="Montessori" nzValue="montessori"></nz-option>
              <nz-option nzLabel="Mother Goose" nzValue="motherGoose"></nz-option>
              <nz-option nzLabel="Nature-Based" nzValue="natureBased"></nz-option>
              <nz-option nzLabel="Play-Based" nzValue="playBased"></nz-option>
              <nz-option nzLabel="Project-Based" nzValue="projectBased"></nz-option>
              <nz-option nzLabel="Reggio Emilia" nzValue="reggioEmilia"></nz-option>
              <nz-option nzLabel="Religious" nzValue="religious"></nz-option>
              <nz-option nzLabel="Technology-Based" nzValue="technologyBased"></nz-option>
              <nz-option nzLabel="Therapeutic" nzValue="therapeutic"></nz-option>
              <nz-option nzLabel="Waldorf" nzValue="waldorf"></nz-option>
          </nz-select>
          Schedule <nz-select
          [nzMaxTagCount]="5"
          nzMode="multiple"
          nzShowSearch
          [(ngModel)]="scheduleSearch">
            <nz-option nzLabel="After Care" nzValue="afterCare"></nz-option>
            <nz-option nzLabel="Before Care" nzValue="beforeCare"></nz-option>
            <nz-option nzLabel="Drop In" nzValue="dropIn"></nz-option>
            <nz-option nzLabel="Evening Care" nzValue="eveningCare"></nz-option>
            <nz-option nzLabel="Full Time" nzValue="fullTime"></nz-option>
            <nz-option nzLabel="Online" nzValue="online"></nz-option>
            <nz-option nzLabel="24 Hour" nzValue="24hour"></nz-option>
            <nz-option nzLabel="Overnight" nzValue="overnight"></nz-option>
            <nz-option nzLabel="Part Time" nzValue="parttime"></nz-option>
            <nz-option nzLabel="Weekend" nzValue="weekend"></nz-option>
        </nz-select>
        Additional Info <nz-select
        [nzMaxTagCount]="5"
        nzMode="multiple"
        nzShowSearch
        [(ngModel)]="additionalInfoSearch">
          <nz-option nzLabel="Camps" nzValue="camps"></nz-option>
          <nz-option nzLabel="Kids Classes" nzValue="kidsClasses"></nz-option>
          <nz-option nzLabel="Homework Assistance" nzValue="homeworkAssistance"></nz-option>
          <nz-option nzLabel="Kindergarten" nzValue="kindergarten"></nz-option>
          <nz-option nzLabel="Tutoring" nzValue="tutoring"></nz-option>
          <nz-option nzLabel="Out-of-School Care" nzValue="outOfSchoolCare"></nz-option>
          <nz-option nzLabel="Summer Care" nzValue="summerCare"></nz-option>
          <nz-option nzLabel="Online Programs" nzValue="onlinePrograms"></nz-option>
          <nz-option nzLabel="TK/VPK" nzValue="tkvpk"></nz-option>
      </nz-select>
        Accomodations <nz-select
        [nzMaxTagCount]="5"
        nzMode="multiple"
        nzShowSearch
        [(ngModel)]="accomodationsSearch">
          <nz-option nzLabel="Outdoor Space" nzValue="outdoorSpace"></nz-option>
          <nz-option nzLabel="Field Trips" nzValue="fieldTrips"></nz-option>
          <nz-option nzLabel="Diapers Provided" nzValue="diapersProvided"></nz-option>
          <nz-option nzLabel="Formula Provided" nzValue="formulaProvided"></nz-option>
          <nz-option nzLabel="Meals Provided" nzValue="mealsProvided"></nz-option>
          <nz-option nzLabel="Transportation Services" nzValue="transportationServices"></nz-option>
        </nz-select>
        Medical <nz-select
        [nzMaxTagCount]="5"
        nzMode="multiple"
        nzShowSearch
        [(ngModel)]="medicalSearch">
          <nz-option nzLabel="Administers Medications" nzValue="administersMedications"></nz-option>
          <nz-option nzLabel="CPR Certified" nzValue="cprCertified"></nz-option>
          <nz-option nzLabel="First Aid Certified" nzValue="firstAidCertified"></nz-option>
          <nz-option nzLabel="Health Screenings" nzValue="healthScreenings"></nz-option>
          <nz-option nzLabel="Special Needs Care" nzValue="specialNeedsCare"></nz-option>
        </nz-select>
        <br>
        Language <nz-select
        [nzMaxTagCount]="5"
        nzMode="multiple"
        nzShowSearch
        [(ngModel)]="languageSearch">
          <nz-option nzLabel="American Sign Language" nzValue="americanSignLanguage"></nz-option>
          <nz-option nzLabel="Amharic" nzValue="amharic"></nz-option>
          <nz-option nzLabel="Arabic" nzValue="arabic"></nz-option>
          <nz-option nzLabel="Armenian" nzValue="armenian"></nz-option>
          <nz-option nzLabel="Chinese (Mandarin or Cantonese)" nzValue="chineseMandarinOrCantonese"></nz-option>
          <nz-option nzLabel="Croatian" nzValue="croatian"></nz-option>
          <nz-option nzLabel="Czech" nzValue="czech"></nz-option>
          <nz-option nzLabel="English" nzValue="english"></nz-option>
          <nz-option nzLabel="French" nzValue="french"></nz-option>
          <nz-option nzLabel="German" nzValue="german"></nz-option>
          <nz-option nzLabel="Greek" nzValue="greek"></nz-option>
          <nz-option nzLabel="Haitan Creole" nzValue="haitanCreole"></nz-option>
          <nz-option nzLabel="Hebrew" nzValue="hebrew"></nz-option>
          <nz-option nzLabel="Hindi" nzValue="hindi"></nz-option>
          <nz-option nzLabel="Italian" nzValue="italian"></nz-option>
          <nz-option nzLabel="Japanese" nzValue="japanese"></nz-option>
          <nz-option nzLabel="Korean" nzValue="korean"></nz-option>
          <nz-option nzLabel="Latin" nzValue="latin"></nz-option>
          <nz-option nzLabel="Latvian" nzValue="latvian"></nz-option>
          <nz-option nzLabel="Lithuanian" nzValue="lithuanian"></nz-option>
          <nz-option nzLabel="Persian" nzValue="persian"></nz-option>
          <nz-option nzLabel="Polish" nzValue="polish"></nz-option>
          <nz-option nzLabel="Portugese" nzValue="portugese"></nz-option>
          <nz-option nzLabel="Punjabi" nzValue="punjabi"></nz-option>
          <nz-option nzLabel="Romanian" nzValue="romanian"></nz-option>
          <nz-option nzLabel="Russian" nzValue="russian"></nz-option>
          <nz-option nzLabel="Serbian" nzValue="serbian"></nz-option>
          <nz-option nzLabel="Slovenian" nzValue="slovenian"></nz-option>
          <nz-option nzLabel="Spanish" nzValue="spanish"></nz-option>
          <nz-option nzLabel="Tagalog" nzValue="tagalog"></nz-option>
          <nz-option nzLabel="Ukranian" nzValue="ukranian"></nz-option>
          <nz-option nzLabel="Urdu" nzValue="urdu"></nz-option>
          <nz-option nzLabel="Vietnamese" nzValue="vietnamese"></nz-option>
          <nz-option nzLabel="Other" nzValue="other"></nz-option>
        </nz-select>
        Financial Aid <nz-select
        [nzMaxTagCount]="5"
        nzMode="multiple"
        nzShowSearch
        [(ngModel)]="financialAidSearch">
          <nz-option nzLabel="Offers Financial Aid" nzValue="offersFinancialAid"></nz-option>
          <nz-option nzLabel="Free Meals" nzValue="freeMeals"></nz-option>
          <nz-option nzLabel="Free Tuition" nzValue="freeTuition"></nz-option>
          <nz-option nzLabel="Military Assistance" nzValue="militaryAssistance"></nz-option>
          <nz-option nzLabel="Scholarship" nzValue="scholarship"></nz-option>
          <nz-option nzLabel="Subsidies & Vouchers" nzValue="subsidiesVouchers"></nz-option>
          <nz-option nzLabel="Sibling Discount" nzValue="siblingDiscount"></nz-option>
          <nz-option nzLabel="Sliding Fee Scale" nzValue="slidingFeeScale"></nz-option>
        </nz-select>
        Head Start <nz-select
        [nzMaxTagCount]="5"
        nzMode="multiple"
        nzShowSearch
        [(ngModel)]="headStartSearch">
          <nz-option nzLabel="Head Start" nzValue="headStart"></nz-option>
          <nz-option nzLabel="Early Head Start" nzValue="earlyHeadStart"></nz-option>
          <nz-option nzLabel="Migrant & Seasonal Head Start" nzValue="migrantSeasonalHeadStart"></nz-option>
        </nz-select>
        Quality <nz-select
        [nzMaxTagCount]="5"
        nzMode="multiple"
        nzShowSearch
        [(ngModel)]="qualitySearch">
          <nz-option nzLabel="NAC" nzValue="nac"></nz-option>
          <nz-option nzLabel="NAEYC" nzValue="naeyc"></nz-option>
          <nz-option nzLabel="NAFCC" nzValue="nafcc"></nz-option>
          <nz-option nzLabel="NECPA" nzValue="necpa"></nz-option>
        </nz-select>
          <br>
        </p>
          <nz-radio-group [(ngModel)]="searchModel">
            <label nz-radio nzValue="network">Network</label>
            <label nz-radio nzValue="sites">Provider Sites</label>
          </nz-radio-group>
        <!-- <ul class="options">
          <li class="option optionCheckbox">
            <label nz-checkbox [(ngModel)]="serarchNetwork">Network</label>
          </li>
          <li class="option optionCheckbox">
            <label nz-checkbox [(ngModel)]="serarchSites">Provider Sites </label>
          </li>
          <li class="option">Press enter to search</li>
        </ul> -->
        <div *ngIf="!showResult" class="results">
          <div class="resultsTitle">
            <span>Press ENTER to search, ESC to close</span>
          </div>
        </div>
        <div *ngIf="showResult" class="results">
          <div class="resultsTitle">
            <span>Search Results:</span>
          </div>
          <div class="row">
            <div class="col-lg-6">
              
                <div *ngFor="let item of resutls; let i = index">
                  <div class="resultContent">
                  <div class="resultThumb">{{i+1}}</div> 
                  <div class="resultThumb">
                    <!-- <nz-avatar nzIcon="bank" [nzSrc]="item.mainLogo[0]?.url || ''"></nz-avatar> -->
                    <!-- <img [src]="item.mainLogo[0].url" /> -->
                  </div>
                  <div class="result" style="display:flex; flex-direction: row; justify-content: left; align-items: center">
                    <div>
                      <img src=item.logo alt="Missing Logo">
                    </div>
                    <div>
                      <a style="font-size:30px;" [href]="'/#/products/careconnect/provider/'+item.entityId" target="_blank">
                        {{item.programName}}</a>
                        <br>
                        <ng-container *ngFor="let tag of tagLists[i]">
                          <nz-tag>{{tag}}</nz-tag>
                        </ng-container>
                        <br>
                        {{item.programDescription}}
                        <br> 
                        <a [href]="''+item.logoUrl" target="_blank">Logo: </a>
                        <a [href]="''+item.video" target="_blank">Video: </a>
                        <a [href]="''+item.yelp" target="_blank">Yelp: </a>
                        <a [href]="''+item.facebook" target="_blank">Facebook: </a>
                      <!-- <a [href]="'/#/products/carewait/workspace/'+item.itemId" target="_blank">
                      <div class="resultText">{{item.networkName}}</div></a> -->
                      <div class="resultSource" nz-typography nzEllipsis nzExpandable [nzEllipsisRows]="3"  >
                      {{item.familyServiceDescription}}
                     </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>