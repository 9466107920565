<nz-page-header class="site-page-header">
  <!--breadcrumb-->
  <!-- <nz-breadcrumb nz-page-header-breadcrumb>
      <nz-breadcrumb-item>First-level Menu</nz-breadcrumb-item>
      <nz-breadcrumb-item>
        <a>Second-level Menu</a>
      </nz-breadcrumb-item>
      <nz-breadcrumb-item>Third-level Menu</nz-breadcrumb-item>
    </nz-breadcrumb> -->

  <!--avatar-->
  <nz-avatar nz-page-header-avatar nzSrc="/assets/mct/mct_preloader.full.png"></nz-avatar>

  <!--title-->
  <nz-page-header-title>CareCloud Support Center</nz-page-header-title>

  <!--subtitle-->
  <nz-page-header-subtitle>MCT Technology</nz-page-header-subtitle>

  <!--tags-->
  <!-- <nz-page-header-tags>
      <nz-tag [nzColor]="'blue'">Running</nz-tag>
    </nz-page-header-tags> -->

  <!--extra-->
  <!-- <nz-page-header-extra>
      <button nz-button>Operation</button>
      <button nz-button>Operation</button>
      <button nz-button nzType="primary">Primary</button>
      <button nz-button nzNoAnimation nz-dropdown [nzDropdownMenu]="menu" style="border: none; padding: 0">
        <i nz-icon nzType="ellipsis" nzTheme="outline" style="font-size: 20px; vertical-align: top;"></i>
      </button>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item>1st menu item length</li>
          <li nz-menu-item>2nd menu item length</li>
          <li nz-menu-item>3rd menu item length</li>
        </ul>
      </nz-dropdown-menu>
    </nz-page-header-extra> -->

  <!--content-->
  <nz-page-header-content>
    <div nz-row>
      <div class="content">
        <p nz-paragraph>
          ITOPS2 will support and manage all products based on CareCloud 4, including, CareWai2, CareConnect,
          CareControl4. and third-party support platforms and products.
        </p>
        <p nz-typography><strong>Quick Start</strong></p>
        <!-- <p nz-paragraph>
          Quick link to useful pages
        </p>
        <div nz-typography>
          <ul>
            <li>
              <a [routerLink]="['/products/carewait/workspace']" routerLinkActive="router-link-active">CareWait
                Workspace</a>
            </li>
            <li>
              <a [routerLink]="['/appbuilder/forms']" routerLinkActive="router-link-active">FormIo Forms</a>
            </li>
            <li>
              <a [routerLink]="['/products/carecontrol/portalagent']" routerLinkActive="router-link-active">Portal
                Agent</a>
            </li>
          </ul>
        </div> -->

        <div class="content-link">
          <!-- <a href="https://mcttechnology.atlassian.net/servicedesk/customer/portal/3" target="_blank">
            <i class="fa fa-user text-info fa-2x" alt="jira"></i>
            HR Request
          </a>
          <a href="https://mcttechnology.atlassian.net/servicedesk/customer/portal/4" target="_blank">
            <i class="fa fa-tv text-info fa-2x" alt="jira"></i>
            IT Request
          </a> -->
          <a href="https://mcttechnology.atlassian.net/jira/projects?selectedProjectType=software" target="_blank">
            <i class="fa fa-check-square-o text-info fa-2x" alt="jira"></i>
            Project Management (JIRA)
          </a>
          <a href="https://mcttechnology.atlassian.net/wiki/home" target="_blank">
            <i class="fa fa-book text-info fa-2x" alt="doc"></i>
            Product Docs (Confluence)
          </a>
          <a href="https://mcttechnology.zendesk.com/hc/en-us" target="_blank">
            <i class="fa fa-h-square text-info fa-2x" alt="doc"></i>
            Customer Support (Zendesk)
          </a>
        </div>
      </div>
      <div class="content-image">
        <img src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg" alt="content" />
      </div>
    </div>
  </nz-page-header-content>
</nz-page-header>
<nz-list nzGrid>
  <div nz-row [nzGutter]="16">
    <div nz-col   nzSm="24" nzLg="12" nzXl="8">
      <nz-list-item>
        <nz-card [nzTitle]="avatarCCN">
          <!-- <nz-card-meta
          [nzAvatar]="avatarCCN"
          nzTitle="CareConnect"
        ></nz-card-meta> -->
          <p nz-paragraph><b>Live:</b></p>
          <p nz-paragraph>
            Provider: <a href="https://careconnect.carecloud.io/ " target="_blank">https://careconnect.carecloud.io/</a><i class="ml-1 fa fa-external-link"></i>
          </p>
          <p nz-paragraph>
            Parent: <a href="https://careconnectfamily.carecloud.io/ " target="_blank">https://careconnectfamily.carecloud.io/</a><i class="ml-1 fa fa-external-link"></i>
          </p>
          <p nz-paragraph><b>UAT:</b></p>
          <p nz-paragraph>
            <a href="https://ustest-careconnect.carecloud.io/ "
              target="_blank">https://ustest-careconnect.carecloud.io/</a><i class="ml-1 fa fa-external-link"></i>
          </p>
          <p nz-paragraph>
            Parent: <a href="https://ustest-careconnectfamily.carecloud.io/ " target="_blank">https://ustest-careconnectfamily.carecloud.io/</a><i class="ml-1 fa fa-external-link"></i>
          </p>
          <p nz-paragraph><b>Account Info: </b></p>
          <p nz-paragraph>  <a [routerLink]="['/user/security']" routerLinkActive="router-link-active">Get Support Account Password</a></p>
        </nz-card>
      </nz-list-item>
    </div>
    <div nz-col nzSm="24" nzLg="12" nzXl="8">
      <nz-list-item>
        <nz-card [nzTitle]="avatarCC">
          <p nz-paragraph><b>Live:</b></p>
          <p nz-typography nzCopyable nzCopyText="https://CODE.cc4.carecloud.io/ ">
            Office: <a href="https://cc4.carecloud.io/ " target="_blank">https:// --Customer Code-- .cc4.carecloud.io/</a>
          </p>
          <p  nz-paragraph>
            Parent: <a href="https://careconnectfamily.carecloud.io/ " target="_blank"><u>https://careconnectfamily.carecloud.io/</u></a><i class="ml-1 fa fa-external-link"></i>
          </p>
          <p nz-paragraph><b>UAT:</b></p>
          <p nz-typography nzCopyable nzCopyText="https://CODE.ustest-cc4.carecloud.io/ ">
            Office: <a href="https://ustest-cc4.carecloud.io/" target="_blank">https://--Customer Code--.ustest-cc4.carecloud.io/</a>
          </p>
          <p  nz-paragraph>
            Parent: <a href="https://ustest-careconnectfamily.carecloud.io/ " target="_blank">https://ustest-careconnectfamily.carecloud.io/</a><i class="ml-1 fa fa-external-link"></i>
          </p>
          <p nz-paragraph><b>Account Info: </b></p>
            <p nz-paragraph><a
              href="https://mcttechnology.atlassian.net/wiki/spaces/TEAM/pages/2568127802/CC4+Account+and+URL"
              target="_blank"><u>CC4 Account and URL</u></a><i class="ml-1 fa fa-external-link"></i> </p> 
        </nz-card>
      </nz-list-item>
    </div>
    <div nz-col nzSm="24" nzLg="12" nzXl="8">
      <nz-list-item>
        <nz-card [nzTitle]="avatarCW">
          <p nz-paragraph><b>Live:</b></p>
          <p  nz-typography nzCopyable nzCopyText="https://carewait2.carecloud.io/">
            Office: <a href="https://carewait2.carecloud.io/ " target="_blank">https://carewait2.carecloud.io/--Workspace Code--</a>
          </p>
          <p nz-paragraph>
            Parent Landing: <a href="https://carewait2-family.carecloud.io/ "
              target="_blank">https://carewait2-family.carecloud.io/--Workspace Code--</a><i class="ml-1 fa fa-external-link"></i>
          </p>
          <p nz-paragraph><b>UAT:</b></p>
          <p  nz-typography nzCopyable nzCopyText="https://ustest-carewait-mct-v2.carecloud.io/">
            Office: <a href="https://ustest-carewait-mct-v2.carecloud.io/"
              target="_blank">https://ustest-carewait-mct-v2.carecloud.io/--Workspace Code--</a>
          </p>
          <p nz-paragraph>
            Parent Landing: <a href="https://ustest-carewait-mct-family-v2.carecloud.io/"
              target="_blank">https://ustest-carewait-mct-family-v2.carecloud.io/--Workspace Code--</a><i class="ml-1 fa fa-external-link"></i>
          </p>
          <p nz-paragraph><b>Account Info: </b><a
              href="https://mcttechnology.atlassian.net/wiki/spaces/TEAM/pages/2562195700/CW2.0+Accounts+and+URLs"
              target="_blank">CW2.0 Accounts and URLs</a> <i class="ml-1 fa fa-external-link"></i></p>
        </nz-card>
      </nz-list-item>
    </div>
    <div nz-col nzSm="24" nzLg="12" nzXl="8">
      <nz-list-item>
        <nz-card nzTitle="Customer Portal">
          <p nz-paragraph><b>Funder Portal:</b></p>
          <p nz-paragraph>
            Live: <a href="https://funder.carecloud.io/ " target="_blank">https://funder.carecloud.io/</a><i class="ml-1 fa fa-external-link"></i>
          </p>
          <p nz-paragraph>
            UAT: <a href="https://ustest-funder.carecloud.io/ " target="_blank">https://ustest-funder.carecloud.io/</a><i class="ml-1 fa fa-external-link"></i>
          </p>
          <p nz-paragraph><b>Agency Portal:</b></p>
          <p nz-paragraph>
            Live: <a href="https://agency.carecloud.io/" target="_blank">https://agency.carecloud.io/</a><i class="ml-1 fa fa-external-link"></i>
          </p>
          <p nz-paragraph>
            UAT: <a href="https://ustest-agency.carecloud.io/"
              target="_blank">https://ustest-agency.carecloud.io/</a><i class="ml-1 fa fa-external-link"></i>
          </p>
          <p nz-paragraph><b>PayCheck Portal</b></p>
          <p nz-paragraph>
            <a href="https://myapps.paychex.com" target="_blank">https://myapps.paychex.coml</a><i class="ml-1 fa fa-external-link"></i>
          </p>
        </nz-card>
      </nz-list-item>
    </div>
    <!-- <div nz-col nzSm="24" nzLg="12" nzXl="8">
      <nz-list-item>
        <nz-card nzTitle="HR Request">
          <p nz-paragraph><b>PayCheck Portal</b></p>
          <p nz-paragraph>
            <a href="https://myapps.paychex.com" target="_blank">https://myapps.paychex.coml</a><i class="ml-1 fa fa-external-link"></i>
          </p>
          <p nz-paragraph><b>Onboarding and Offboarding</b></p>
          <p nz-paragraph>
            <a href="https://mcttechnology.atlassian.net/servicedesk/customer/portal/3" target="_blank">HR Portal</a><i class="ml-1 fa fa-external-link"></i>
          </p>
        </nz-card>
      </nz-list-item>
    </div> -->
    <!-- <div nz-col nzSm="24" nzLg="12" nzXl="8">
      <nz-list-item>
        <nz-card nzTitle="IT Request">
          <p nz-paragraph><b>Request New Account or Permission</b></p>
          <p nz-paragraph>
            <a href="https://mcttechnology.atlassian.net/servicedesk/customer/portal/4" target="_blank">IT Portal</a><i class="ml-1 fa fa-external-link"></i>
          </p>
          
        </nz-card>
      </nz-list-item>
    </div> -->
  </div>
</nz-list>
<ng-template #avatarCCN>
  <img nz-image width="120px" src="/assets/mct/careconnect-logo.png" /> Care Connect
</ng-template>
<ng-template #avatarCW>
  <img nz-image width="120px" src="/assets/mct/cw2-logo.png" />   Care Wait 2
</ng-template>
<ng-template #avatarCC>
  <img nz-image height="25px" src="/assets/mct/mct_preloader.full.png" />   Care Control 4
</ng-template>