<div>
  <div nz-dropdown nzTrigger="click" class="dropdown" [nzDropdownMenu]="menu" (nzVisibleChange)="badgeCountIncrease()">
    <nz-badge [nzCount]="badgeCount">
      <i class="icon fe fe-bell"></i>
    </nz-badge>
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <div class="card vb__utils__shadow width-400">
      <div class="card-body p-0">
        <div>
          <nz-tabset class="tabs vb-tabs-bordered" [nzSelectedIndex]="0">
            <nz-tab nzTitle="Messages">
              <div #talkjsContainer style="height: 600px">Loading...</div>
            </nz-tab>
            <nz-tab nzTitle="Contacts">

            </nz-tab>
          </nz-tabset>
        </div>
      </div>
    </div>
  </nz-dropdown-menu>
</div>
