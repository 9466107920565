import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, LOCALE_ID, APP_INITIALIZER } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { NgProgressModule } from "@ngx-progressbar/core";
import { NgProgressRouterModule } from "@ngx-progressbar/router";
import { NgProgressHttpModule } from "@ngx-progressbar/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { reducers, metaReducers } from "./store/reducers";
import { UserEffects } from "./store/user/effects";

// locale resistration
import { registerLocaleData } from "@angular/common";
import { default as localeEn } from "@angular/common/locales/en";
import { NZ_I18N, en_US as localeZorro } from "ng-zorro-antd/i18n";
import { NgxPermissionsModule } from "ngx-permissions";
import { AppSetupService } from "./app.init.setup";
import { FormioAppConfig } from "@formio/angular";
import { environment } from "src/environments/environment";

const LOCALE_PROVIDERS = [
  { provide: LOCALE_ID, useValue: "en" },
  { provide: NZ_I18N, useValue: localeZorro }
];
registerLocaleData(localeEn, "en");

export function SetupApp(setup: AppSetupService) {
  return () => setup.initliaze();
}

export const FormIoConfig: FormioAppConfig = {
  appUrl: environment.formio.api,
  apiUrl: environment.formio.api,
  icons: "fontawesome"
};
@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,

    // translate
    TranslateModule.forRoot(),

    // ngrx
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([UserEffects]),
    StoreRouterConnectingModule.forRoot(),

    // nprogress
    NgProgressModule.withConfig({
      thick: true,
      spinner: false,
      color: "#0190fe"
    }),
    NgProgressRouterModule,
    NgProgressHttpModule,
    NgxPermissionsModule.forRoot()
  ],
  providers: [
    AppSetupService,
    {
      provide: APP_INITIALIZER,
      useFactory: SetupApp,
      deps: [AppSetupService],
      multi: true
    },
    { provide: FormioAppConfig, useValue: FormIoConfig },
    // locale providers
    ...LOCALE_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
