import { Injectable } from "@angular/core";
import * as moment from "moment";

export interface QueryOption {
  RequireTotalCount?: any;
  RequireGroupCount?: any;
  IsCountQuery?: any;
  Skip?: number;
  Take?: number;
  Sort?: string;
  Group?: string;
  Filter?: string;
  TotalCount?: number;
  Select?: string[];
  TotalSummary?: SummaryInfo[];
  GroupSummary?: SummaryInfo[];
  RemoteGrouping?: boolean;
  PrimaryKey?: string[];
  DefaultSort?: string;
}
export class SummaryInfo {
  Selector?: string;
  SummaryType?: string;
}
export class Pager {
  CurrentPage?: number;
  StartIndex?: any;
  PageSize: number;
  totalRecords?: number;
  TotalPageNumber?: number;
}
@Injectable()
export class ConvertService {
  /**
   *  DxGrid loadOption to Cc.Query Option
   */
  public convertDxGridOption(option): QueryOption {
    const data = {
      RequireTotalCount: option.requireTotalCount,
      Skip: option.skip,
      Take: option.take,
      Sort: JSON.stringify(option.sort),
      Group: JSON.stringify(option.group),
      Select: [],
      Filter: ""
    };
    if (Array.isArray(option.filter)) {
      data.Filter = JSON.stringify(option.filter);
    }
    return data;
  }
  // Search API to filter
  public convertDxLookUpOption(option): QueryOption {
    if (!option) {
      return {
        RequireTotalCount: false,
        Skip: 0,
        Take: 20,
        Sort: "",
        Group: "",
        Select: [],
        Filter: ""
      };
    }
    const data = {
      RequireTotalCount: option.requireTotalCount,
      Skip: option.skip,
      Take: option.take,
      Sort: JSON.stringify(option.sort),
      Group: JSON.stringify(option.group),
      Select: [],
      Filter: ""
    };
    let filter = [];
    if (option.searchExpr instanceof Array) {
      for (let i = 0; i < option.searchExpr.length; i++) {
        const opt = [];
        opt.push(option.searchExpr[i]);
        opt.push(option.searchOperation);
        opt.push(option.searchValue);
        filter.push(opt);
        if (i < option.searchExpr.length - 1) {
          filter.push("or");
        }
      }
    } else {
      if (option.searchExpr && option.searchValue !== "") {
        const opt = [];
        opt.push(option.searchExpr);
        opt.push(option.searchOperation);
        opt.push(option.searchValue);
        filter.push(opt);
      } else {
        if (option.filter instanceof Array) {
          const opt = [];
          opt.push(option.filter[0]);
          opt.push(option.filter[1]);
          opt.push(option.filter[2]);
          filter.push(opt);
        } else {
          // data.Skip = 0;
          // data.Take = 20;
          filter = null;
        }
      }
    }
    if (Array.isArray(filter)) {
      data.Filter = JSON.stringify(filter);
    }
    return data;
  }
  // End

  /**
   * Auto Query
   *
   */
  public convertAutoOption(gridOptions) {
    let options: any = {
      Skip: gridOptions.skip,
      Take: gridOptions.take,
      OrderBy: "",
      OrderByDesc: "",
      Fields: "",
      Include: ""
    };

    if (gridOptions.sort) {
      for (const item of gridOptions.sort) {
        if (item.desc) {
          options.OrderByDesc += item.selector + ",";
        } else {
          options.OrderBy += item.selector + ",";
        }
      }
    }

    options = this.getParams(gridOptions.filter, options);
    const params = new URLSearchParams();
    // tslint:disable-next-line:forin
    for (const key in options) {
      if (options[key]) {
        params.set(key, options[key]);
      }
    }
    return params.toString();
  }

  getParams(option, obj) {
    if (!obj) {
      obj = {};
    }
    if (option instanceof Array) {
      // [filed, op , value]
      if (option.length === 3 && typeof option[0] === "string") {
        let op = this.getOp(option[1]);
        if (option[2] == null || option[2] == "" || option[2] === "null") {
          if (option[1] == "=") {
            op = option[0] + "IsNull";
            obj[op] = "true";
          } else if (option[1] == "<>") {
            op = option[0] + "IsNotNull";
            obj[op] = "true";
          } else {
            op = option[0] + op;
            obj[op] = option[2];
          }
        } else {
          op = option[0] + op;
          obj[op] = option[2];
        }
      } else {
        option.forEach(item => this.getParams(item, obj));
      }
    }
    return obj;
  }

  getOp(opchar) {
    if (!opchar) {
      return "Contains";
    }
    switch (opchar) {
      case "=": {
        return "";
      }
      case "startswith": {
        return "StartsWith";
      }
      case "endswith": {
        return "EndsWith";
      }
      case "<>": {
        return "!";
      }
      case ">": {
        return "GreaterThan";
      }
      case ">=": {
        return "GreaterThanOrEqualTo";
      }
      case "<": {
        return "LessThan";
      }
      case "<=": {
        return "LessThanOrEqualTo";
      }
      case "in": {
        return "In";
      }
      default:
        return "Contains";
    }
  }

  public convertToPager(option): Pager {
    if (!option || !option.take) {
      return {
        CurrentPage: 0,
        StartIndex: 0,
        PageSize: 10000
      };
    }
    const data = {
      CurrentPage: option.skip / option.take + 1,
      StartIndex: option.skip,
      PageSize: option.take
    };

    return data;
  }

  /**
   * convert .Net Json Date /Date(1507625658000-0000)/
   */
  public convertNetJsonToDate(value): Date {
    if (!value) {
      return null;
    }
    const date = moment(value);
    return date.toDate();
    // return new Date( +(value.replace('/Date(', '')));
  }

  public convertStringToDate(value, format = "YYYYMMDD"): Date {
    if (!value) {
      return null;
    }
    const date = moment(value, format);
    return date.toDate();
  }

  public convertStringToDateTime(value, format = "YYYYMMDDHHmmss"): Date {
    if (!value) {
      return null;
    }
    const date = moment(value, format);
    return date.toDate();
  }

  public formatDate(value: Date, format = "YYYYMMDD"): string {
    if (!value) {
      return null;
    }
    return moment(value).format(format);
  }

  public formatDateTime(value: Date, format = "YYYYMMDDHHmmss"): string {
    if (!value) {
      return null;
    }
    return moment(value).format(format);
  }
  // End

  /**
   *  filled a special field to conver Date format
   * */
  public convertDateField(
    model: any,
    dataField: string,
    format = "YYYYMMDD",
    isGet: boolean = true,
    prefix = "m_"
  ) {
    const nField = prefix + dataField;
    if (isGet) {
      model[nField] = this.convertStringToDate(model[dataField], format);
    } else {
      model[dataField] = this.formatDate(model[nField], format);
    }
  }

  public getDateField(model: any, dataField: string, format = "YYYYMMDD") {
    this.convertDateField(model, dataField, format);
  }

  public setDateField(model: any, dataField: string, format = "YYYYMMDD") {
    this.convertDateField(model, dataField, format, false);
  }
  // End

  // dataGrid options to formIo search options
  public convertFormIoOption(options) {
    const query = {
      limit: options.take,
      skip: options.skip,
      sort: "-created"
    };
    if (options.sort) {
      query.sort = (options.sort[0].desc ? "-" : "") + options.sort[0].selector;
    }

    const filter = this.getFilter(options.filter, {});
    return {
      ...query,
      ...filter
    };
  }

  getFilter(option, obj) {
    if (!obj) {
      obj = {};
    }
    if (option instanceof Array) {
      // [filed, op , value]
      if (option.length === 3 && typeof option[0] === "string") {
        const op = this.getFormIoOp(option[0], option[1], option[2]);
        // op = option[0] + op;
        obj[op.key] = op.value;
      } else {
        option.forEach(item => this.getFilter(item, obj));
      }
    }
    return obj;
  }

  getFormIoOp(field, opchar, value) {
    let key = field;
    let v = value;
    let op = "";
    if (!opchar) {
      op = "";
    }
    switch (opchar) {
      case "=": {
        op = "";
        break;
      }
      case "<>": {
        op = "__ne";
        break;
      }
      case ">": {
        op = "__gt";
        break;
      }
      case ">=": {
        op = "__gte";
        break;
      }
      case "<": {
        op = "__lt";
        break;
      }
      case "<=": {
        op = "__lte";
        break;
      }
      case "contains": {
        op = "__regex";
        v = `/${value}/i`;
        break;
      }
      case "notcontains": {
        op = "__regex";
        v = `/^((?!${value}).)*$/i`; // /^((?!Gr).)*$/i
        break;
      }
      case "startswith": {
        op = "__regex";
        v = `/^${value}/i`;
        break;
      }
      case "endswith": {
        op = "__regex";
        v = `/${value}$/i`;
        break;
      }
      default:
        op = "";
        break;
    }
    return { key: key + op, value: v };
  }

  // dxForm Data
  public convertToDxFormData(data: any) {
    if (!data) {
        return {
        };
    }
    // tslint:disable-next-line:forin
    for (const att in data) {
        const val = data[att];
        if (val) {
            if ( val === 'true' || val === 'True') {
                data[att] = true;
            } else if (val === 'false' || val === 'False') {
                data[att] = false;
            } else {
                let v = val.toString();
                if (v.startsWith('[') && v.endsWith(']')) {
                    v = v.replace('[','').replace(']','');
                    data[att] = v.split(',');
                }
            }
        }
    }
    return data;
}
}
