import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";
import { datadogRum } from "@datadog/browser-rum";
import { select, Store } from "@ngrx/store";
import * as Reducers from "src/app/store/reducers";
// import { AuthService } from "./core/auth/auth.service";
// import { ActivatedRoute, Router } from "@angular/router";
// import { Observable, of , from} from 'rxjs';
// import { catchError, concatAll, map, shareReplay, switchMap } from 'rxjs/operators';
// import * as _ from 'lodash';
// import { parse } from 'qs';
@Injectable({
  providedIn: "root"
})
export class AppSetupService {
  // currentUser$: Observable<any>;
  constructor(private store: Store<any>) {
    this.store.pipe(select(Reducers.getUser)).subscribe(u => {
      if (u && u.authorized) {
        datadogRum.setUser({
          userId: u.UserId,
          name: u.UserName
        });
      }
    });
  }

  public initliaze() {
    if (environment.production) {
      datadogRum.init({
        applicationId: "df8e5ec9-1dd0-4215-afd3-81a77905aecb",
        clientToken: "pub817104bdd929b52aab06ae3a4fdfdb5b",
        site: "datadoghq.com",
        service: "itops2",
        sampleRate: 50,
        sessionReplaySampleRate: 0,
        trackInteractions: true,
        //trackResources: true,
        //trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input"
      });
      // datadogRum.startSessionReplayRecording();
    }
  }

  // initCurrentUser() {
  //   const accessCode = this.getQueryStringByKey('AccessCode');
  //   console.log(this.route.snapshot.queryParams);
  //   // login with accessCode or cookie
  //   if (accessCode) {
  //     this.currentUser$ = from(this.authService
  //       .loginWithToken(accessCode));
  //       // .pipe(switchMap((u) => {
  //       //   return from(this.authService.currentAccount());
  //       // } ));
  //   } else {
  //     this.currentUser$ = from(this.authService.currentAccount()); // login width cookie
  //   }
  //   // Convert Observable to share Observable, just like BehaviorSubject
  //   // this.currentUser$ = this.currentUser$.pipe(shareReplay(1));
  //   this.currentUser$.subscribe(u => {
  //     if (u) {
  //       const returnUrl = this.getQueryStringByKey('returnUrl');
  //       if (returnUrl) {
  //         this.router.navigateByUrl(returnUrl); // // redirect to returnUrl
  //       } else if (this.router.url.includes('/home')) {
  //         this.router.navigate(['/']); // redirect to root route on auth pages
  //       } else if (accessCode) {
  //         // this.router.navigate([this.router.url.split('?')[0]]);
  //         this.router.navigate([this.router.url]);
  //       }
  //     }
  //   });
  // }
  // getQueryStringByKey = key => {
  //   const routerParams = this.route.snapshot.queryParams;
  //   const urlQueryString = decodeURIComponent(location.href.split('?')[1] || '');
  //   const browserUrlParams = parse(urlQueryString);
  //   return _.get(routerParams, key) || _.get(browserUrlParams, key);
  // };
}
