
<div class="footer">
  <div class="footerInner">
    <div class="">
      Copyright © {{ date }}
      <a href="https://www.mcttechnology.com" target="_blank" rel="noopener noreferrer">
        MCT Technology Inc.
      </a> | {{ settings.logo }} - {{ version }}
    </div>
  </div>
</div>