export interface IReturn<T> {
  createResponse(): T;
}

export interface IReturnVoid {
  createResponse(): void;
}

export interface IHasSessionId {
  SessionId: string;
}
export interface IHasBearerToken {
  BearerToken: string;
}

export interface IPost {}
// @DataContract
export class ResponseError {
  // @DataMember(Order=1)
  public ErrorCode: string;

  // @DataMember(Order=2)
  public FieldName: string;

  // @DataMember(Order=3)
  public Message: string;

  // @DataMember(Order=4)
  public Meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseError>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseStatus {
  // @DataMember(Order=1)
  public ErrorCode: string;

  // @DataMember(Order=2)
  public Message: string;

  // @DataMember(Order=3)
  public StackTrace: string;

  // @DataMember(Order=4)
  public Errors: ResponseError[];

  // @DataMember(Order=5)
  public Meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken {
  // @DataMember(Order=11)
  public ResponseStatus: ResponseStatus;

  // @DataMember(Order=1)
  public UserId: string;

  // @DataMember(Order=2)
  public SessionId: string;

  // @DataMember(Order=3)
  public UserName: string;

  // @DataMember(Order=4)
  public DisplayName: string;

  // @DataMember(Order=5)
  public ReferrerUrl: string;

  // @DataMember(Order=6)
  public BearerToken: string;

  // @DataMember(Order=7)
  public RefreshToken: string;

  // @DataMember(Order=8)
  public ProfileUrl: string;

  // @DataMember(Order=9)
  public Roles: string[];

  // @DataMember(Order=10)
  public Permissions: string[];

  // @DataMember(Order=12)
  public Meta: { [index: string]: string };

  public constructor(init?: Partial<AuthenticateResponse>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost {
  // @DataMember(Order=1)
  public provider: string;

  // @DataMember(Order=2)
  public state: string;

  // @DataMember(Order=3)
  public oauth_token: string;

  // @DataMember(Order=4)
  public oauth_verifier: string;

  // @DataMember(Order=5)
  public userName: string;

  // @DataMember(Order=6)
  public password: string;

  // @DataMember(Order=7)
  public rememberMe?: boolean;

  // @DataMember(Order=9)
  public errorView: string;

  // @DataMember(Order=10)
  public nonce: string;

  // @DataMember(Order=11)
  public uri: string;

  // @DataMember(Order=12)
  public response: string;

  // @DataMember(Order=13)
  public qop: string;

  // @DataMember(Order=14)
  public nc: string;

  // @DataMember(Order=15)
  public cnonce: string;

  // @DataMember(Order=16)
  public useTokenCookie?: boolean;

  // @DataMember(Order=17)
  public accessToken: string;

  // @DataMember(Order=18)
  public accessTokenSecret: string;

  // @DataMember(Order=19)
  public scope: string;

  // @DataMember(Order=20)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<Authenticate>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AuthenticateResponse();
  }
  public getTypeName() {
    return "Authenticate";
  }
}
