import * as _ from "lodash";
export class FluentDxToolBarItem {
  widget = "dxButton";
  location = "before";
  locateInMenu = "auto";
  toolbar = "bottom";
  options: any = {};
  visible: boolean = true;
  disabled: boolean = false;

  public After() {
    this.location = "after";
    return this;
  }

  public Center() {
    this.location = "center";
    return this;
  }

  public Before() {
    this.location = "before";
    return this;
  }

  public Location(f: "after" | "before" | "center") {
    this.location = f;
    return this;
  }

  public LocateInMenu(f: "always" | "auto" | "never") {
    this.locateInMenu = f;
    return this;
  }

  public Visible(visible = true) {
    this.visible = visible;
    return this;
  }

  public Disable(disable = false) {
    this.disabled = disable;
    return this;
  }

  public Data(t?) {
    if (_.isUndefined(t)) {
      return this.Option("data");
    }
    this.Option("data", t);
    return this;
  }
  public Option(name?, value?) {
    if (_.isUndefined(name)) {
      return this.options;
    }
    if (name && _.isUndefined(value)) {
      return this.options[name];
    }
    this.options[name] = value;
    return this;
  }
}
export class FluentDxButton extends FluentDxToolBarItem {
  public Icon(icon) {
    this.options.icon = icon;
    return this;
  }

  public Disable(disabled = false) {
    this.options.disabled = disabled;
    return this;
  }

  public Text(t) {
    this.Option("text", t);
    return this;
  }

  public ElementAttr(t) {
    this.Option("elementAttr", t);
    return this;
  }

  public Type(type: "back" | "danger" | "default" | "normal" | "success") {
    this.Option("type", type);
    return this;
  }

  public Click(f) {
    this.options.onClick = f;
    return this;
  }

  public StylingMode(type: "text" | "outlined" | "contained") {
    this.Option("stylingMode", type);
    return this;
  }
}

export class FluentDxDropDownButton extends FluentDxToolBarItem {
  dropDownOptions: any = {};
  items: any[] = [];

  constructor() {
    super();
    this.widget = "dxDropDownButton";
    this.Option("dropDownOptions", this.dropDownOptions);
    this.Option("items", this.items);
  }

  public Icon(icon) {
    this.options.icon = icon;
    return this;
  }

  public Text(t) {
    this.Option("text", t);
    return this;
  }

  public Disable(t) {
    this.Option("disabled", t);
    return this;
  }

  public ElementAttr(t) {
    this.Option("elementAttr", t);
    return this;
  }

  public DisplayExpr(t) {
    this.Option("displayExpr", t);
    return this;
  }

  public Width(t) {
    this.dropDownOptions.width = t;
    return this;
  }

  public DropDownOption(name, value) {
    this.dropDownOptions[name] = value;
    return this;
  }

  public Items(t) {
    this.Option("items", t);
    return this;
  }

  public DataSource(t) {
    this.Option("dataSource", t);
    return this;
  }

  public AddItem(text, icon = "", data = {}) {
    this.items.push({
      text: text,
      icon: icon,
      data: data
    });
    return this;
  }

  public ItemClick(f) {
    this.options.onItemClick = f;
    return this;
  }
  public Click(f) {
    this.options.onClick = f;
    return this;
  }
}

export class FluentDxDropDownSelection extends FluentDxDropDownButton {
  constructor() {
    super();
    this.Option("useSelectMode", true);
    this.Option("displayExpr", "Name");
    this.Option("keyExpr", "Code");
  }

  public SelectionChanged(f) {
    this.options.onSelectionChanged = f;
    return this;
  }

  public AddSelectionItem(text, value) {
    this.options.items.push({
      Name: text,
      Code: value
    });
    return this;
  }

  public SelectKey(key) {
    this.options["selectedItemKey"] = key;
    return this;
  }
}

export class FluentDxTextBox extends FluentDxToolBarItem {
  constructor() {
    super();
    this.widget = "dxTextBox";
    this.options.buttons = [];
  }

  public PreButton(name, options) {
    this.options.buttons.push({
      location: "before",
      name: name,
      options: options
    });
    return this;
  }

  public EnterKey(f) {
    this.options.onEnterKey = f;
    return this;
  }

  public Placeholder(text) {
    this.options.placeholder = text;
    return this;
  }
}

export class FluentDxToolBar {
  public static Title(text, template = null) {
    const btn = FluentDxToolBar.Button()
      .Center()
      .LocateInMenu("never")
      .StylingMode("text");
    if (!template) {
      template = `<b> ${text} </b`;
    }
    return btn.Option("template", template);
    //return  FluentDxToolBar.Button().Option('template', `<b> ${text} </b`).Center().LocateInMenu('never').StylingMode('text');
  }
  public static Button(text = "") {
    return new FluentDxButton().Text(text);
  }

  public static DropDownButton(text = "") {
    return new FluentDxDropDownButton().Text(text);
  }

  public static TextBox() {
    return new FluentDxTextBox();
  }

  public static DropDownSelection() {
    return new FluentDxDropDownSelection();
  }
}
