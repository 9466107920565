import {
  Directive,
  Input,
  ViewContainerRef,
  ChangeDetectorRef,
  TemplateRef,
  SimpleChanges,
  OnChanges
} from "@angular/core";

import {
  NgxPermissionsDirective,
  NgxPermissionsService,
  NgxPermissionsConfigurationService,
  NgxRolesService
} from "ngx-permissions";

@Directive({ selector: "[appPermission]" })
export class AppWithPermissionDirective extends NgxPermissionsDirective
  implements OnChanges {
  @Input() appPermission: string | string[];
  @Input() appPermissionThen: TemplateRef<any>;
  @Input() appPermissionElse: TemplateRef<any>;

  constructor(
    permissionsService: NgxPermissionsService,
    configurationService: NgxPermissionsConfigurationService,
    rolesService: NgxRolesService,
    viewContainer: ViewContainerRef,
    changeDetector: ChangeDetectorRef,
    templateRef: TemplateRef<any>
  ) {
    super(
      permissionsService,
      configurationService,
      rolesService,
      viewContainer,
      changeDetector,
      templateRef
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const change = changes.appPermission;
    this.ngxPermissionsOnly = change.currentValue;
    if (this.ngxPermissionsOnly && this.ngxPermissionsOnly instanceof Array) {
      this.ngxPermissionsOnly.push("ADMINISTRATOR");
      const exc = [];
      this.ngxPermissionsOnly.forEach(item => {
        exc.push("!" + item);
      });
      this.ngxPermissionsExcept = exc;
    } else if (this.ngxPermissionsOnly) {
      this.ngxPermissionsOnly = [change.currentValue, "ADMINISTRATOR"];
      this.ngxPermissionsExcept = "!" + this.ngxPermissionsOnly;
    }
    if (this.appPermissionElse) {
      this.ngxPermissionsElse = this.appPermissionElse;
    }
    if (this.appPermissionThen) {
      this.ngxPermissionsThen = this.appPermissionThen;
    }
  }
}
