import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { SharedModule } from "src/app/shared/shared.module";
import { PlatformAlertEventListComponent } from "./Alert/event.list.component";
import { BreadcrumbsComponent } from "./Breadcrumbs/breadcrumbs.v2.component";
import { FooterComponent } from "./Footer/footer.component";
import { MenuClassicLeftComponent } from "./MenuClassic/MenuLeft/menu-left.component";
import { MenuClassicTopComponent } from "./MenuClassic/MenuTop/menu-top.component";
import { TopbarActionsComponent } from "./Topbar/Actions/actions.component";
import { TopbarHelpComponent } from "./Topbar/Help/help.component";
import { TopbarLanguageSwitcherComponent } from "./Topbar/LanguageSwitcher/language-switcher.component";
import { AppTopbarNotificationComponent } from "./Topbar/Notification/component";
import { TopbarSearchComponent } from "./Topbar/Search/search.component";
import { TopbarSupportComponent } from "./Topbar/SupportMenu/support.component";
import { TopbarComponent } from "./Topbar/topbar.component";
import { TopbarUserMenuComponent } from "./Topbar/UserMenu/user-menu.component";
import { VariantsComponent } from "./Variants/variants.component";

const COMPONENTS = [
  TopbarComponent,
  TopbarActionsComponent,
  TopbarLanguageSwitcherComponent,
  TopbarUserMenuComponent,
  TopbarSearchComponent,
  MenuClassicLeftComponent,
  MenuClassicTopComponent,
  BreadcrumbsComponent,
  FooterComponent,
  VariantsComponent,
  AppTopbarNotificationComponent,
  TopbarHelpComponent,
  TopbarSupportComponent,
  PlatformAlertEventListComponent
];

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS]
})
export class CoreComponentsModule {}
