import { Injectable } from "@angular/core";
import { JsonServiceClient } from "@servicestack/client";
import { NgxPermissionsService, NgxRolesService } from "ngx-permissions";
import { environment } from "src/environments/environment";
import { Authenticate } from "./auth.model";
import * as _ from "lodash";
import { BehaviorSubject } from "rxjs";
@Injectable({
  providedIn: "root"
})
export class AuthService {
  private roles: Map<string, any>;
  constructor(
    private permissionService: NgxPermissionsService,
    private roleService: NgxRolesService
  ) {}

  async loginWithPassword(username, password, provider = "basic") {
    const client = this.getAuthClient();
    client.userName = username;
    client.password = password;
    return await client.postToUrl(
      "/auth",
      new Authenticate({
        provider: provider,
        rememberMe: true
      })
    );
    // return await client.postToUrl(
    //   "/auth",
    //   new Authenticate({
    //     provider: "credentials",
    //     userName: username,
    //     password: password,
    //     rememberMe: true
    //   })
    // );
  }

  async loginWithToken(token = null, provider = "carecloud") {
    const client = this.getAuthClient();
    if (token) {
      return await client.postToUrl(
        "/auth",
        new Authenticate({
          provider: provider,
          accessToken: token
        })
      );
    }
    return null;
  }

  async currentAccount() {
    const client = this.getAuthClient();
    const user = await client.postToUrl("/auth", new Authenticate());
    user.Permissions = await this.getPermissions(user.UserName);
    if (user.Permissions) {
      await this.loadPermissions(user.Permissions);
    }
    return user;
  }

  logout() {
    this.permissionService.flushPermissions();
    // document.cookie
    const client = this.getAuthClient();
    return client.postToUrl("/auth", new Authenticate({ provider: "logout" }));
  }

  async loadPermissions(permissions) {
    this.roleService.flushRolesAndPermissions();
    const permissonData = await this.getRolesPermissions(permissions);
    // console.log(permissonData);
    permissonData.forEach((value: any, key: string) => {
      if (key == "ADMINISTRATOR") {
        this.roleService.addRole("ADMINISTRATOR", () => {
          return true;
        });
      } else {
        this.roleService.addRoleWithPermissions(key, value);
      }
    });
    return true;
  }

  private async getPermissions(logonname) {
    // const url =
    //   "/api/signin/auth/user/permissions?AppId=" +
    //   environment.ccGateway.product_id;
    const url =
      "/api/platform/formio/0-itops2adminuser/submission?data.logonName=" +
      _.toLower(logonname);
    const client = this.getAuthClient();
    const permissions = await client.get<any>(url);
    if (permissions) {
      const usergroup = permissions[0].data.userGroups;
      const url2 = "/api/platform/formio/0-itops2adminusergroup/submission";
      const rolesData = await client.get<any>(url2, {
        "data.code__in": usergroup,
        "limit": 50
      });
      let roles = [];
      _.each(rolesData, role => {
        roles = _.concat(roles, role.data.roles);
      });
      return roles;
    }
    return [];
  }

  private async getRolesPermissions(roles) {
    const url = "/api/platform/formio/0-itops2adminrole/submission";
    const client = this.getAuthClient();
    const permissionData = await client.get<any>(url, {
      "data.code__in": roles,
      "limit": 200
    });
    const rolePermission = new Map<string, any>();
    permissionData.forEach(role => {
      rolePermission.set(
        role.data.code,
        _.union(role.data.menus, role.data.permissions)
      );
    });
    return rolePermission;
  }

  private getAuthClient(): JsonServiceClient {
    const url = environment.ccGateway.baseUrl;
    // environment.ccGateway.enabled
    //   ? environment.ccGateway.baseUrl
    //   : "";
    const jc = new JsonServiceClient(url);
    return jc;
  }
}
