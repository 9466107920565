import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";
import { getMenuData } from "./config";
import { getTicketMenuData } from "./ticket.menu";

@Injectable({
  providedIn: "root"
})
export class MenuService {
  breadcrumbs: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  
  constructor(
    private route: ActivatedRoute
  ) {}

  setBreadcrumbs(menus: any[]) {
    this.breadcrumbs.next(menus);
  }

  getMenuData(code?: string): Observable<any[]> {
    if (code && code.startsWith('/ticket/')) {
      getTicketMenuData.forEach(item => {
        item.root = code;
      });
      return of(getTicketMenuData);
    } else {
      return of(getMenuData);
    }
    
  }
}
