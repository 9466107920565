import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import * as _ from 'lodash';
import { NzOptionItemComponent } from 'ng-zorro-antd/select';
import { dxDataGridService } from './dxDataGridService';

export interface appGridOption {
        appRemote?: boolean,
        appTop?: number,
        autoHeight?: string,
        showExport?: boolean,
        showRefresh?: boolean,
        showColumnChooser?: boolean,
        showHeaderFilter?: boolean,
        showFilter?:boolean,
        ShowFilterPanel?: boolean,
        showSearch?: boolean,
        searchOption?:
            {
                visible: boolean,
                text?: string,
                width?: number
            }
}

@Directive({ 
    selector: 'dx-data-grid[appGridOption]',
    exportAs:"appDxGrid",
    providers: [],
 })
export class dxDataGridSerivceDirective implements OnInit, OnChanges {
    @Input() appGridOption:appGridOption;
    @Input() buttons: any[];
    gridService: dxDataGridService;
    defaultOption: appGridOption = {
        appRemote : false,
        showExport: true,
        showRefresh: true,
        showColumnChooser: false,
        showHeaderFilter: false,
        showSearch: false,
        showFilter: false,
        // autoHeight: '70vh'
    }
    constructor(
        public elementRef: ElementRef,
        public dataGrid: DxDataGridComponent,
    ) {
        
     }
    ngOnInit(): void {
        this.gridService = new dxDataGridService(this.dataGrid.instance);
        const option: appGridOption = _.merge( this.defaultOption,this.appGridOption);
        const remote = option.appRemote? 'remote' : 'local';
        this.gridService.initGrid(remote);
        // this.gridService.Option({toolbar: {items: this.buttons}})
        if (option.showRefresh) {
            this.gridService.ShowRefresh()
        }
        if (option.showExport) {
            this.gridService.ShowExport()
        }
        if (option.showColumnChooser) {
            this.gridService.ShowColumnChooser()
        }
        if (option.showHeaderFilter) {
            this.dataGrid.instance.option("headerFilter", { visible: true });
        }
        if (option.showFilter) {
            this.dataGrid.instance.option("filterRow", { visible: true });
        }
        if (option.ShowFilterPanel) {
            this.dataGrid.instance.option("filterPanel", { visible: true });
        }
        if (option.showSearch) {
            // this.gridService.ShowSearch(option.searchOption?.width, option.searchOption?.text);
            this.dataGrid.toolbar.items.push({
                name: 'searchPanel',
                // location: 'before'
            }),
            this.dataGrid.instance.option("searchPanel", {
                visible: true,
                width: option.searchOption?.width,
                placeholder: option.searchOption?.text,
                highlightSearchText: true
            });
        }
        
        if (this.buttons) {
            // this.gridService.ShowButtons(this.buttons);
            this.dataGrid.toolbar.items.push(...this.buttons)
            
        }
        // this.gridService.AutoHeight(200, option.appTop);
        if (option.autoHeight) this.gridService.setHeight(option.autoHeight);
    }
    ngOnChanges(changes: SimpleChanges): void {
        if(changes.appGridOption) {
            
        }
        
    }

    setHeight(height) {
        this.gridService.setHeight(height);
    }
}