import { Component, OnInit } from "@angular/core";
function getWindow(): any {
  return window;
}
@Component({
  selector: "app-topbar-help",
  styleUrls: ["style.scss"],
  template: `
    <a class="dropdown" (click)="showHelp()">
      <i class="icon fe fe-help-circle"></i>
    </a>
  `
})
export class TopbarHelpComponent implements OnInit {
  constructor() {}
  showHelp() {
    const zE = getWindow().zE;
    zE("webWidget", "show");
    zE("webWidget", "toggle");
  }
  ngOnInit() {}
}
