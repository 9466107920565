import { Component } from "@angular/core";
import { select, Store } from "@ngrx/store";
import * as Reducers from "src/app/store/reducers";
import { environment } from "src/environments/environment";

@Component({
  selector: "vb-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent {
  settings: any;
  date = new Date().getFullYear();
  version: any;

  constructor(private store: Store<any>) {
    this.version = environment.version;
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.settings = state;
    });
  }
}
