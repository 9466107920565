<div>
  <div nz-dropdown nzTrigger="click" class="dropdown" [nzDropdownMenu]="menu">
    <nz-avatar [nzSrc]="avatarSrc" [nzShape]="'circle'" [nzSize]="'default'" class="avatar"></nz-avatar>
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item>
        <strong>{{ 'topBar.profileMenu.hello' | translate }}, {{name || 'Anonymous'}}</strong>
  
      <li nz-menu-divider></li>
      <li nz-menu-item>
        <a href [routerLink]="['/user/profile',userId]"
        routerLinkActive="router-link-active" >
          <i class="fe fe-user mr-2"></i>
          {{ 'topBar.profileMenu.editProfile' | translate }}
        </a>
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item (click)="logout()">
        <i class="fe fe-log-out mr-2"></i>
        {{ 'topBar.profileMenu.logout' | translate }}
        <!-- <a href="javascript: void(0);" >
         
        </a> -->
      </li>
    </ul>
  </nz-dropdown-menu>
</div>