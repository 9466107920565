import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "src/app/shared/shared.module";
import { LayoutsModule } from "src/app/layouts/layouts.module";
import { AppPreloader } from "src/app/app-routing-loader";

// layouts & notfound
import { LayoutAuthComponent } from "src/app/layouts/Auth/auth.component";
import { LayoutMainComponent } from "src/app/layouts/Main/main.component";

// pages
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { CCAuthGuard } from "./core/auth/auth.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full"
  },
  {
    path: "files",
    data: { title: "Files" },
    loadChildren: () =>
      import("src/app/files/files.module").then(m => m.AppFilesModule)
  },
  {
    path: "",
    component: LayoutMainComponent,
    canActivate: [CCAuthGuard],
    children: [
      {
        path: "dashboard",
        data: { title: "Dashboard" },
        component: DashboardComponent
      },
      {
        path: "user",
        data: { title: "User" },
        loadChildren: () =>
          import("src/app/routes/user/user.module").then(m => m.AppUserModule)
      },
      {
        path: "customer",
        data: { title: "Customer" },
        loadChildren: () =>
          import("src/app/routes/customer/customer.module").then(
            m => m.AppCustomerModule
          )
      },
      {
        path: "ticket",
        data: { title: "Ticket" },
        loadChildren: () =>
          import("src/app/routes/ticketportal/ticketportal.module").then(
            m => m.TicketPortalModule
          )
      },
      {
        path: "products/carewait",
        data: { title: "CareWait" },
        loadChildren: () =>
          import("src/app/routes/carewait/carewait.module").then(
            m => m.CarewaitModule
          )
      },
      {
        path: "products/carecontrol",
        data: { title: "Care Control 4" },
        loadChildren: () =>
          import("src/app/routes/carecontrol/carecontrol.module").then(
            m => m.CareControlModule
          )
      },
      {
        path: "products/careconnect",
        data: { title: "Care Connect" },
        loadChildren: () =>
          import("src/app/routes/careconnect/careconnect.module").then(
            m => m.CareConnectModule
          )
      },
      {
        path: "products/funderportal",
        data: { title: "Funder Portal" },
        loadChildren: () =>
          import("src/app/routes/funderportal/funderportal.module").then(
            m => m.FunderPortalModule
          )
      },
      {
        path: "products/agencyportal",
        data: { title: "Agency Portal" },
        loadChildren: () =>
          import("src/app/routes/agencyportal/agencyportal.module").then(
            m => m.AgencyPortalModule
          )
      },
      {
        path: "appbuilder",
        data: { title: "App Builder" },
        loadChildren: () =>
          import("src/app/routes/appbuilder/appbuilder.module").then(
            m => m.AppBuilderModule
          )
      },
      {
        path: "usage",
        data: { title: "Usage Reports" },
        loadChildren: () =>
          import("src/app/routes/usage/usage.module").then(
            m => m.AppUsageModule
          )
      },
      {
        path: "admin",
        data: { title: "Admin" },
        loadChildren: () =>
          import("src/app/routes/admin/admin.module").then(
            m => m.AppAdminModule
          )
      }
     
    ]
  },
  {
    path: "home",
    component: LayoutAuthComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("src/app/pages/home.module").then(m => m.AppHomeModule)
      }
    ]
  },
  {
    path: "**",
    redirectTo: "/home/404"
  }
];

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: AppPreloader,
      relativeLinkResolution: "legacy"
    }),
    LayoutsModule
  ],
  declarations: [DashboardComponent],
  providers: [AppPreloader],
  exports: [RouterModule]
})
export class AppRoutingModule {}
