import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-dashboard",
  styleUrls: ["component.scss"],
  templateUrl: "./dashboard.component.html"
})
export class DashboardComponent implements OnInit {
  data = [
    {
      title: "Title 1"
    },
    {
      title: "Title 2"
    },
    {
      title: "Title 3"
    },
    {
      title: "Title 4"
    }
  ];
  constructor() {}
  ngOnInit() {}
}
