import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-alert-event-list',
    template: `<nz-list [nzDataSource]="dataSource" [nzRenderItem]="item" [nzItemLayout]="'horizontal'">
    <ng-template #item let-item>
      <nz-comment [nzAuthor]="item.alert_type" [nzDatetime]="fromNow(item.date_happened)">
        <nz-avatar *ngIf="item.alert_type=='warning'" style="background-color: #f56a00" nz-comment-avatar nzIcon="warning"  ></nz-avatar>
        <nz-avatar *ngIf="item.alert_type=='error'" class="bg-danger" nz-comment-avatar nzIcon="stop" ></nz-avatar>
        <nz-avatar *ngIf="item.alert_type=='info'" class="bg-info" nz-comment-avatar nzIcon="info-circle" ></nz-avatar>
     
        <nz-comment-content>
          <p>{{ item.title }}</p>
          <p>{{ item.text }}</p>
          <p><span nz-icon nzType="tags" nzTheme="outline"></span>: <span class="text-muted">{{ item.tags }}</span></p>
        </nz-comment-content>
        <!-- <nz-comment-action>Subscribe</nz-comment-action> -->
      </nz-comment>
    </ng-template>
  </nz-list>`
})

export class PlatformAlertEventListComponent implements OnInit {
    @Input() dataSource;
    constructor() { }
    fromNow(n) {
        return moment.unix(n).fromNow()
    }
    ngOnInit() { }
}