import { Component, Input, OnInit } from "@angular/core";
import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute
} from "@angular/router";
import { Store } from "@ngrx/store";
import { filter } from "rxjs/operators";
import { reduce } from "lodash";
import { MenuService } from "src/app/core/services/menu";

@Component({
  selector: "vb-breadcrumbs",
  templateUrl: "./breadcrumbs.component.html",
  styleUrls: ["./breadcrumbs.component.scss"]
})
export class BreadcrumbsComponent implements OnInit {
  menuData: any[];
  breadcrumbs: any[];

  constructor(
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    menuService.breadcrumbs.subscribe(ms => (this.breadcrumbs = ms));
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.breadcrumbs = [];
        const r = this.route.snapshot.firstChild.data;
        this.breadcrumbs.push({
          title: r.title ?? "Current",
          url: event.url
        });
      });
  }

  ngOnInit() {}
}
