import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject } from "rxjs";

import { Deferred } from "src/app/shared/utils/deffered.util";
import { environment } from "src/environments/environment";
import Talk from "talkjs";
import * as Reducers from "src/app/store/reducers";
import { AuthenticateResponse } from "src/app/core/auth/auth.model";
import { JsonClientFactoryService } from "src/app/core/services/JsonClientFactory.service";
import { GetUserPictureRequest } from "src/app/routes/user/services/model";

@Injectable({
  providedIn: "root"
})
export class TalkService {
  public static APP_ID = environment.message.msg_appId || "tNNQuZi1";
  private static APP_user_prefix =
    environment.message.msg_user_prefix || "cc_user_";
  private static APP_sysnotification_prefix =
    environment.message.msg_sysnotification_prefix ||
    "carecloud_sysnotification_";

  public currentTalkUser: Talk.User;

  private loadedPopups: Talk.Popup[];
  public currentSessionDeferred = new Deferred<Talk.Session>();

  public unread: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private unreadCount = 0;
  constructor(
    private clientFactory: JsonClientFactoryService,
    private store: Store<any>
  ) {
    this.loadedPopups = [];
    this.store.pipe(select(Reducers.getUser)).subscribe((u: AuthenticateResponse) => {
        if (u) {
            this.createCurrentSession(u);
        }
      });
  }

  async createUser(userId, userName) {
    await Talk.ready;
    const userPhoto = await this.getUserPicture(userId);
    const user = await this.createTalkUser(userId, userName, userPhoto);
    return user;
  }
  async createUserSession(userId, userName) {
    await Talk.ready;
    const user = await this.createUser(userId, userName);
    const session = new Talk.Session({
         appId: TalkService.APP_ID,
         me: user
    });
    return session;
  }

  async createCurrentSession(user: AuthenticateResponse) {
    await Talk.ready;

    if (user) {
      const userPhoto = await this.getUserPicture(user.UserId);
      const currentTalkUser = await this.createTalkUser(
        user.UserId,
        user.UserName || user.DisplayName,
        userPhoto
      );
      const session = new Talk.Session({
        appId: TalkService.APP_ID,
        me: currentTalkUser
      });
      this.currentTalkUser = currentTalkUser;
      // unread message count
      // session.unreads.on('change', unreadConversations => {
      //   const amountOfUnreads = unreadConversations.length;
      //   if (amountOfUnreads == 0) {
      //     this.unreadCount = 0;
      //   } else {
      //     this.unreadCount++;
      //   }
      //   this.unread.next(this.unreadCount);
      // });
      this.currentSessionDeferred.resolve(session);
    }
  }

  async createTalkUser(userId, userName, userPhoto): Promise<Talk.User> {
    await Talk.ready;
    return new Talk.User({
      id: TalkService.APP_user_prefix + userId,
      name: userName || "user",
      photoUrl: userPhoto || "assets/images/avatars/avatar-2.png",
      role: "systemuser"
      // configuration: 'demo_default',
      // welcomeMessage: applicationUser.chatPreferences.chatWelcomeMessage
    });
  }

  async createPopup(
    otherTalkUser: Talk.User,
    keepOpen: boolean
  ): Promise<Talk.Popup> {
    const session = await this.currentSessionDeferred.promise;
    const conversationBuilder = await this.getOrCreateConversation(
      session,
      otherTalkUser
    );
    const popup = session.createPopup(conversationBuilder, {
      keepOpen: keepOpen
    });
    this.loadedPopups.push(popup);
    return popup;
  }

  async createChatbox(otherTalkUser: Talk.User): Promise<Talk.Chatbox> {
    const session = await this.currentSessionDeferred.promise;
    const conversationBuilder = await this.getOrCreateConversation(
      session,
      otherTalkUser
    );
    return session.createChatbox(conversationBuilder);
  }

  async createSystemNotificationBox(): Promise<Talk.Chatbox> {
    const session = await this.currentSessionDeferred.promise;
    const cnovationId =
      TalkService.APP_sysnotification_prefix + this.currentTalkUser.id;
    const conversationBuilder = await session.getOrCreateConversation(
      cnovationId
    );
    conversationBuilder.setAttributes({ subject: "System Notification" });
    const options = {
      showChatHeader: false,
      messageField: {
        visible: false
      }
    };
    conversationBuilder.setParticipant(this.currentTalkUser, {
      access: "ReadWrite"
    });
    // conversationBuilder.setParticipant(this.sysNotificationUser);
    return session.createChatbox(conversationBuilder, options);
  }

  async createInbox(): Promise<Talk.Inbox> {
    const session = await this.currentSessionDeferred.promise;

    return session.createInbox();
    // return session.createInbox({ messageField: { visible: { access: ['==', 'ReadWrite'] } } });
  }

  destroyAllLoadedPopups() {
    if (this.loadedPopups.length > 0) {
      this.loadedPopups.forEach(p => p.destroy());
      this.loadedPopups = [];
    }
  }

  private async getOrCreateConversation(
    session: Talk.Session,
    otherTalkUser: Talk.User
  ) {
    // const otherTalkUser = await this.createTalkUser(otherApplicationUser);
    const conversationBuilder = session.getOrCreateConversation(
      Talk.oneOnOneId(this.currentTalkUser, otherTalkUser)
    );
    conversationBuilder.setParticipant(this.currentTalkUser);
    conversationBuilder.setParticipant(otherTalkUser);
    return conversationBuilder;
  }

  private async getUserPicture(userid) {
    const adminClient = this.clientFactory.AdminClient;
    const postData = new GetUserPictureRequest();
    postData.RequestUserId = userid;
    const response = await adminClient.post<any>(postData);
    if (response.IsSuccess) {
      if (response.PictureUrl && response.PictureUrl.length > 0) {
        return response.PictureUrl;
      }
    } else {
      return "";
    }
  }
}
