import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';
import * as _ from 'lodash';


export interface appFormOption {
}

@Directive({ 
    selector: 'dx-form[appFormOption]',
    exportAs: 'appDxForm',
    providers: [],
 })
export class dxFormSerivceDirective implements OnInit {
    @Input() appFormOption:appFormOption;
    public UpdateFields: any = {};
    
    private modelBackup;

    constructor(
        public elementRef: ElementRef,
        public form: DxFormComponent,
    ) {
        
     }
    beginEdit() {
        this.form.readOnly = false;
        // this.UpdateFields = {};
        this.modelBackup = Object.assign({}, this.form.formData);
    }
    endEdit() {
        this.form.readOnly = true;
        this.UpdateFields = {};
        this.modelBackup = {};
    }
    cancelEdit() {
        this.form.readOnly = true;
        this.UpdateFields = {};
        this.form.formData = Object.assign({}, this.modelBackup);
    }
    ngOnInit(): void {
        this.form.instance.option("onFieldDataChanged", this.onFieldDataChanged.bind(this));
        // this.form.instance.option('onOptionChanged', this.onOptionChanged.bind(this));
    }
    onOptionChanged(e) {
        // switch(e.name) {
        //     case 'readOnly':
        //         if (e.value) {
        //             this.UpdateFields = {}
        //         }
        //         break;
        // }
    }
     onFieldDataChanged(event) {
        if (!this.form.readOnly) {
            if (event.value && event.value instanceof Array && event.value.length == 0) {
              this.UpdateFields[event.dataField] = null;
          } else {
              this.UpdateFields[event.dataField] = event.value;
          }
        }
      }
}