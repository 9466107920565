import { Component, OnInit, Input } from "@angular/core";
import {
  Router,
  NavigationStart,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd
} from "@angular/router";
import { filter, map, mergeMap } from "rxjs/operators";
import * as _ from "lodash";
import { select, Store } from "@ngrx/store";
import { MenuService } from "src/app/core/services/menu";
import * as SettingsActions from "src/app/store/settings/actions";
import * as Reducers from "src/app/store/reducers";
function getWindow(): any {
  return window;
}
@Component({
  selector: "vb-menu-classic-left",
  templateUrl: "./menu-left.component.html",
  styleUrls: ["./menu-left.component.scss"]
})
export class MenuClassicLeftComponent implements OnInit {
  menuColor: String;
  isMenuShadow: Boolean;
  isMenuUnfixed: Boolean;
  isSidebarOpen: Boolean;
  isMobileView: Boolean;
  leftMenuWidth: Number;
  isMenuCollapsed: Boolean;
  logo: String;
  menuData: any[];
  menuDataActivated: any[];
  // role: String;

  constructor(
    private menuService: MenuService,
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
    private router: Router
  ) {
    // this.store.pipe(select(Reducers.getUser)).subscribe(state => {
    //   this.role = state.role;
    // });
    const menucode = this.activatedRoute.firstChild?.firstChild?.snapshot.params.ticketchannelcode;
    // console.log(this.router);
    const menuroot = menucode? '/ticket/channel/'+menucode : '';
    this.menuService
      .getMenuData(menuroot)
      .subscribe(menuData => (this.menuData = menuData));
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.menuColor = state.menuColor;
      this.isMenuShadow = state.isMenuShadow;
      this.isMenuUnfixed = state.isMenuUnfixed;
      this.isSidebarOpen = state.isSidebarOpen;
      this.isMobileView = state.isMobileView;
      this.leftMenuWidth = state.leftMenuWidth;
      this.isMenuCollapsed = state.isMenuCollapsed;
      this.logo = state.logo;
    });

    this.store
      .pipe(select(Reducers.getCurrentUrl))
      .pipe(
        map(route => route.root),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === "primary")
      )
      .subscribe(route => {
        if (route) {
          const zE = getWindow().zE;
          if (route.data?.tags) {
            // zE('webWidget', 'hide');
            zE("webWidget", "helpCenter:setSuggestions", {
              labels: [route.data?.tags]
            });
          } else if (route.data?.title) {
            // zE('webWidget', 'show');
            zE("webWidget", "helpCenter:setSuggestions", {
              search: route.data?.title
            });
          } else {
            zE("webWidget", "helpCenter:setSuggestions", { url: true });
          }
        }
      });
  }

  ngOnInit() {
    this.activateMenu(this.router.url);
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === "primary"),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        // this.activateMenu(event.url ? event.url : null);
        if (event["parentKey"]) {
          this.activateMenu(event["parentKey"], "key");
        }
        if (event["parent"]) {
          this.activateMenu(event["parent"]);
        }
      });
  }

  activateMenu(url: any, key = "url", menuData = this.menuData) {
    menuData = JSON.parse(JSON.stringify(menuData));
    let pathWithSelection = this.getPath(
      { url: url },
      menuData,
      (entry: any) => entry,
      "url"
    );
    if (key === "key") {
      pathWithSelection = this.getPath(
        { key: url },
        menuData,
        (entry: any) => entry,
        "key"
      );
    }
    if (pathWithSelection) {
      pathWithSelection.pop().selected = true;
      _.each(pathWithSelection, (parent: any) => (parent.open = true));
    }
    this.menuDataActivated = menuData.slice();
  }

  getPath(
    element: any,
    source: any,
    property: any,
    keyProperty = "key",
    childrenProperty = "children",
    path = []
  ) {
    let found = false;
    const getElementChildren = (value: any) => _.get(value, childrenProperty);
    const getElementKey = (value: any) => _.get(value, keyProperty);
    const key = getElementKey(element);
    return (
      _.some(source, (e: any) => {
        if (getElementKey(e) === key) {
          path.push(e);
          return true;
        } else {
          return (found = this.getPath(
            element,
            getElementChildren(e),
            property,
            keyProperty,
            childrenProperty,
            path.concat(e)
          ));
        }
      }) &&
      (found || _.map(path, property))
    );
  }

  toggleSettings() {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isSidebarOpen: !this.isSidebarOpen
      })
    );
  }

  onCollapse(value: any) {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isMenuCollapsed: value
      })
    );
  }

  getUrl(item) {
    return _.template(item.url)({ item });
  }
}
