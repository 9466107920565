import { Component } from "@angular/core";
import { select, Store } from "@ngrx/store";
import * as UserActions from "src/app/store/user/actions";
import * as Reducers from "src/app/store/reducers";
import { JsonClientFactoryService } from "src/app/core/services/JsonClientFactory.service";
import { GetUserPictureRequest } from "src/app/routes/user/services/model";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: "vb-topbar-user-menu",
  templateUrl: "./user-menu.component.html",
  styleUrls: ["./user-menu.component.scss"]
})
export class TopbarUserMenuComponent {
  name = "";
  role = "";
  email = "";
  phone = "";
  userId = "";
  avatarSrc = "";
  constructor(
    private store: Store<any>,
    private router: Router,
    private clientFactory: JsonClientFactoryService
  ) {
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      if (state && state.authorized) {
        this.name = state.UserName;
        this.role = state.Role;
        this.email = state.UserName;
        this.userId = state.UserId;
        // this.avatarSrc = state.ProfileUrl;
        this.getUserPicture(this.userId);
      }
    });
  }

  logout() {
    this.store.dispatch(new UserActions.Logout());
  }

  async getUserPicture(userid) {
    const adminClient = this.clientFactory.AdminClient;
    const postData = new GetUserPictureRequest();
    postData.RequestUserId = userid;
    const response = await adminClient.post<any>(postData);
    if (response.IsSuccess) {
      if (response.PictureUrl && response.PictureUrl.length > 0) {
        this.avatarSrc = response.PictureUrl;
      }
    } else {
      this.avatarSrc = "";
    }
  }
}
