import { Injectable } from "@angular/core";

// import notify from 'devextreme/ui/notify';
import { alert, confirm, custom } from "devextreme/ui/dialog";
import { NzNotificationService } from "ng-zorro-antd/notification";

@Injectable()
export class NotifyService {
  constructor(private notify: NzNotificationService) {}

  showInfo(text: string, time = 2500): void {
    this.notify.info("info", text, { nzDuration: time });
  }

  showSuccess(text: string, time = 2000): void {
    // notify(text, 'success', time);
    this.notify.success("success", text, { nzDuration: time });
  }

  showError(text: string, time = 4500, title: string = "Error"): void {
    // notify(text, 'error', time);
    this.notify.error("Error", text, { nzDuration: time });
  }

  showAlert(text: string, title: string = "Alert"): Promise<void> {
    return alert(text, title);
  }

  showConfirm(text: string, title: string = "Confirm"): Promise<boolean> {
    return confirm(text, title);
  }

  showDialog(text: string, title: string, buttons: any[]): any {
    const options = {
      title: title,
      message: text,
      buttons: buttons
    };
    return custom(options);
  }
}
