<div class="topbar">
  <div>
    <vb-topbar-user-menu></vb-topbar-user-menu>
  </div>
  
  
 
  <div class="mr-3 d-none d-sm-block">
    <app-topbar-help></app-topbar-help>
  </div>
  <div class="mr-3 d-none d-sm-block ">
    <!-- <vb-topbar-actions></vb-topbar-actions> -->
    <app-topbar-notifications></app-topbar-notifications>
  </div>
  <div class="ml-auto mr-md-1 d-none d-sm-block">
    <vb-topbar-search></vb-topbar-search>
  </div>
   <div class="mr-1">
    <app-topbar-support></app-topbar-support>
  </div>
</div>