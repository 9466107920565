import { JsonServiceClient } from "@servicestack/client";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

import { AuthService } from "../auth/auth.service";

import { Router } from "@angular/router";

import { select, Store } from "@ngrx/store";
import * as Reducers from "src/app/store/reducers";
import { AuthAppService } from "../auth/authApp.service";

const baseUrl = environment.ccGateway.baseUrl + "/api";
// environment.ccGateway.enabled
//   ? environment.ccGateway.baseUrl + "/api"
//   : "/api";
// const appId = environment.ccGateway?.app_id || '' ;
@Injectable({
  providedIn: "root"
})
export class JsonClientFactoryService {
  public accesscode;
  public appToken;
  public currentUser: any;
  public currentUserName;
  public currentUserId;
  public currentCustomerId;
  public currentCustomerCode;
  public get baseHost() {
    return baseUrl;
  }
  public get BaseClient() {
    return this.getClient("");
  }
  // Care Cloud API clients
  public get PlatformClient() {
    return this.getClient("/platform");
  }
  public get ItopsClient() {
    return this.getClient("/platform/itops");
    // return this.getClient("/itops2");
  }
  public get FormIoClient() {
    return this.getClient("/platform/formio");
    // return this.getClient("/itops2/formio");
  }

  public get SearchClient() {
    return this.getClient("/platform/search");
    // return this.getClient("/search");
  }
  public get PA3Client() {
    return this.getClient("/pa3server");
    // return this.getClient("/search");
  }
  public get CareTrackClient() {
    return this.getClient("/caretrack");
  }
  public get CareWaitClient() {
    return this.getClient("/mctcwv2");
  }
  public get SubscriptionClient() {
    return this.getClient("/billing");
  }
  public get AdminClient() {
    return this.getClient("/admin");
  }
  public get SigninClient() {
    return this.getClient("/signin");
  }
  public get BillingClient() {
    return this.getClient("/billing");
  }
  public get MessageClient() {
    return this.getClient("/message");
  }

  public get MonitorClient() {
    return this.getClient("/monitor");
  }
  public get FileClient() {
    return this.getClient("/file");
  }

  public get AttendanceClient() {
    return this.getClient("/attendance");
  }
  public get StudentClient() {
    return this.getClient("/mctstuv2");
  }

  public get StudentV1Client() {
    return this.getClient("/student");
  }

  public get MessageV2Client() {
    return this.getClient("/mctmessagev2");
  }
  constructor(
    private store: Store<any>,
    public auth: AuthService // private authApp: AuthAppService, // private router: Router
  ) {
    this.store.pipe(select(Reducers.getUser)).subscribe(u => {
      if (u && u.authorized) {
        this.accesscode = u.Meta.token ?? "";
        this.currentUser = {
          ...u
        };
        this.currentUserId = u.Meta.UserId;
        this.currentUserName = u.UserName;
        this.currentCustomerId = u.Meta.CustomerId;
        this.currentCustomerCode = u.Meta.CustomerCode;
        this.currentUser.CustomerId = this.currentCustomerId;
        this.currentUser.CustomerCode = this.currentCustomerCode;
      } else {
        this.accesscode = undefined;
        this.currentUser = undefined;
        this.currentUserId = undefined;
        this.currentUserName = undefined;
        this.currentCustomerId = undefined;
        this.currentCustomerCode = undefined;
        // this.currentUser.CustomerId = undefined;
        // this.currentUser.CustomerCode = undefined;
      }
    });
    // authApp.apiReady.subscribe(t => {
    //   this.appToken = t;
    // });
  }

  public getUrl(module: string, refUrl?: string): string {
    let url = baseUrl + module;
    if (!url.endsWith("/")) {
      url += "/";
    }
    if (refUrl) {
      url += refUrl;
    }

    return url;
  }

  public getClient(module: string, refUrl?: string): JsonServiceClient {
    let url = baseUrl + module;
    if (!url.endsWith("/")) {
      url += "/";
    }
    if (refUrl) {
      url += refUrl;
    }
    const jc = new JsonServiceClient(url)
    jc.headers.append('x-app-id', 'itops2');
    jc.headers.append('x-app-secret', 'WNobm9sb2d');
    jc.exceptionFilter = (res, err) => {
      if (
        (err.responseStatus && err.responseStatus.errorCode === 401) ||
        res.status == 401
      ) {
        // this.auth.logout();
      }
    };
    return jc;
  }
}
