export const environment = {
  production: false,
  name:'shtest',
  version: "SHtest " + require("../../package.json").version,
  ccGateway: {
    enabled: false,
    app_id: "itops",
    app_secret: "123456",
    baseUrl: "",
    datadog_application_key: "92be6bdb45856bd5836ab05d7ba20690819479e5",
    // baseUrl: "https://ustest-interapi.carecloud.io/gateway/v4",
    ssoUrl: "https://shtest-sso.mcttechnology.cn/Saml/InitiateSingleSignOn",
    sloUrl: "https://shtest-sso.mcttechnology.cn/Saml/InitiateSingleLogout",
    mfaUrl: "https://ustest-login2.carecloud.io/Account/login2fa",
    qrUrl: "https://ustest-login2.carecloud.io/Account/ustest-itops2.carecloud.io/event",
    product_id: "3110"
  },
  message: {
    msg_appId: "tNNQuZi1",
    msg_user_prefix: "shtest_carecloud_user_",
    msg_sysnotification_prefix: "carecloud_sysnotification_"
  },
  formio: {
    api: "https://shtest-itops2.mcttechnology.cn/api/platform/formio",
    fileUploadApi:'https://form.mcttechnology.cn/pdf'
  },
  authenticated: false,
  hmr: false,
  careWaitHost: "https://ustest-carewait-mct-v2.carecloud.io",
  careConnectHost: "https://ustest-careconnect.carecloud.io",
  fileviewerHost: "https://ustest-itops2.carecloud.io/fileviewer/"
};
