import { Component, ViewChild, ElementRef } from "@angular/core";
import { debug } from "console";
import { JsonClientFactoryService } from "src/app/core/services/JsonClientFactory.service";
import { NzSelectModule } from 'ng-zorro-antd/select';
import { TestBed } from "@angular/core/testing";
import { isTemplateRef } from "ng-zorro-antd/core/util";

@Component({
  selector: "vb-topbar-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"]
})
export class TopbarSearchComponent {
  @ViewChild("liveSearchInput") liveSearchInput: ElementRef;

  showSearch: boolean = false;
  searchText: string = "";
  nameSearch: string = "";
  zipCodeOrCitySearch: string = "";
  ageSearch: string = "";
  calendarSearch: string = "";
  minMonthlyFeeSearch: string = "";
  maxMonthlyFeeSearch: string = "";
  minWeeklyFeeSearch: string = "";
  maxWeeklyFeeSearch: string = "";
  availabilitySearch: string = "";
  startTimeSearch: Date;
  endTimeSearch: Date;
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);
  educationOptions: [
    { label: 'Academic Based', value: 'academicBased' },
    { label: 'Arts-Based', value: 'artsBased' },
    { label: 'BAKS', value: 'baks' },
    { label: 'Blended Curriculum', value: 'blendedCurriculum' },
    { label: 'Creative Curriculum', value: 'creativeCurriculum '}
  ]
  optionList = [
    { label: 'Lucy', value: 'lucy'},
    { label: 'Jack', value: 'jack'}
  ];
  selectedValue = [];
  educationSearch: string[] = [];
  scheduleSearch: string[] = [];
  additionalInfoSearch: string[] = [];
  accomodationsSearch: string[] = [];
  medicalSearch: string[] = [];
  languageSearch: string[] = [];
  financialAidSearch: string[] = [];
  headStartSearch: string[] = [];
  qualitySearch: string[] = [];

  showResult = false;
  resutls = [];
  tagLists = [];
  testList = [["test1"],["test2"],["test3"]];
  serarchNetwork = true;
  serarchSites = false;
  searchModel = 'sites';
  searchDaycare = 'daycare';

  constructor(private clientFactory: JsonClientFactoryService) {
    document.addEventListener("keydown", this.handleKeyDown.bind(this), false);
  }

  setVisible() {
    this.showSearch = true;
    setTimeout(() => {
      this.liveSearchInput.nativeElement.focus();
    }, 100);
  }

  setHidden() {
    this.showSearch = false;
    this.showResult = false;
    this.searchText = '';
  }

  handleKeyDown(event: any) {
    if (this.showSearch) {
      const key = event.keyCode.toString();
      if (key === "27") {
        this.setHidden();
      }
      if (key === "13") {
        // Search
        this.showResult = true;
        let results = [];
        if (this.searchModel == 'sites') {
          this.search_sites().then(r => {
            this.tagLists = [];
            results = r;
            for (let i = 0; i < results.length; i++)
            {
              let tagList = [];
              if (results[i].hasOwnProperty('education'))
              {
                for (let j = 0; j < results[i].education.length; j++)
                  tagList.push(results[i].education[j]);
              }
              if (results[i].hasOwnProperty('accommodations'))
              {
                for (let j = 0; j < results[i].accommodations.length; j++)
                  tagList.push(results[i].accommodations[j]);
              }
              if (results[i].hasOwnProperty('calendar'))
                tagList.push(results[i].calendar);
              if (results[i].hasOwnProperty('schedule'))
              {
                for (const item in results[i].schedule)
                  tagList.push(results[i].schedule[item]);
              }
              if (results[i].hasOwnProperty('additionalInfo'))
              {
                for (const item in results[i].additionalInfo)
                  tagList.push(results[i].additionalInfo[item]);
              }
              if (results[i].hasOwnProperty('medical'))
              {
                for (const item in results[i].medical)
                  tagList.push(results[i].medical[item]);
              }
              if (results[i].hasOwnProperty('language'))
              {
                for (const item in results[i].language)
                  tagList.push(results[i].language[item]);
              }
              if (results[i].hasOwnProperty('financialAid'))
              {
                for (const item in results[i].financialAid)
                  tagList.push(results[i].financialAid[item]);
              }
              if (results[i].hasOwnProperty('headStart'))
              {
                for (const item in results[i].headStart)
                  tagList.push(results[i].headStart[item]);
              }
              this.tagLists.push(tagList);
              console.log(results[i].education)
            }
            this.resutls = r;
            console.log(this.tagLists);
            console.log(this.resutls);
          });
        } else {
          this.search_network().then(r => (this.resutls = r));
        }
      }
    }
  }

  async search() {
    const client = this.clientFactory.SearchClient;
    const type = this.serarchSites ? "sites" : "network";
    const url = `/index/${type}/search?Query=${this.searchText}`;
    const response = await client.get<any>(url);
    if (response.IsSuccess) {
      return response.Data.hits.hits.map(t => t._source);
    }
  }

  async search_network() {
    const client = this.clientFactory.SearchClient;
    const url = "/index/network/search";
    const postData = {
      Query: {
        // query: {
        //   query_string: {
        //     query: this.searchText
        //   }
        // }
        sort: [
          {
            manualRankNumber: "asc"
          },
          {
            "networkName.keyword": "asc"
          },
          "_score"
        ],
        from: 0,
        size: 10,
        query: {
          terms: {
            "zipCodeCoverages": this.searchText.split(",")
          }
          // bool: {
          //   should: [
          //     {
          //       match: {
          //         zipCodeCoverages: this.searchText
          //       }
          //     }
          //   ]
          // }
        }
      },
      createResponse() {
        return {} as any;
      }
    };
    const response = await client.postToUrl<any>(url, postData);
    if (response.IsSuccess) {
      return response.Data.hits.hits.map(t => t._source);
    }
  }

  async search_sites() {
    const client = this.clientFactory.SearchClient;
    const url = "/index/sites/search";
    this.optionList = [];
    this.selectedValue = [];
    let minimumShouldMatch = 1; //this is incremented whenever a field is filled out
    //age
    let age = 0;
    if (this.ageSearch.match(/^[0-9]+$/))
    {
      age = parseInt(this.ageSearch);
      this.entered(age, "Age");
      minimumShouldMatch++;
    }
    else 
      age = -1;
    //max weekly/monthly fees
    let maxWeeklyFee = 0;
    let maxMonthlyFee = 0;
    if (!isNaN(parseFloat(this.maxWeeklyFeeSearch)))
    {
      maxWeeklyFee = parseFloat(this.maxWeeklyFeeSearch)
      minimumShouldMatch++;
    }
    else
      maxWeeklyFee = -1;
    if (!isNaN(parseFloat(this.maxMonthlyFeeSearch)))
    {
      maxMonthlyFee = parseFloat(this.maxMonthlyFeeSearch)
      minimumShouldMatch++;
    }
    else
      maxMonthlyFee = -1;
    // zipcode/city
    let zipCode = "";
    let city = "";
    if (this.searchText.length > 0)
    {
      if (this.searchText.length == 5 && this.searchText.match(/^[0-9]+$/))
        zipCode = this.searchText;
      else
        city = this.searchText;
      minimumShouldMatch++;
    }
    //program type
    let programType = [];
    let permanentlyClosed = "";
    if (this.searchDaycare == "daycare")
      programType.push("daycare");
    else if (this.searchDaycare == "preschool")
      programType.push("preschool");
    else if (this.searchDaycare == "both")
    {
      programType.push("daycare");
      programType.push("preschool");
    }
    else if (this.searchDaycare == "closed")
      permanentlyClosed = "permanentlyClosed";
    // calendar
    if (this.calendarSearch != "notSpecified" && this.calendarSearch != "")
      minimumShouldMatch++;
    if (this.nameSearch != "")
      minimumShouldMatch++;
    let searchQuery = {
      "bool": {
        "should": [
          {
            "match": {
              "programName": this.nameSearch
            } 
          },  
          {
            "match": {
              "zipCode": zipCode
            }
          },
          {
            "match": {
              "city": city
            }
          },
          {
            "terms": {
              "programType": programType
            }
          },
          {
            "match": {
              "calendar": this.calendarSearch
            }
          },
          {
            "match": {
              "permanentlyClosed": permanentlyClosed
            }
          },
          {
            "bool": {
            "must": [
              {
                "range": {
                  "maxAge": {
                    "gte": age
                  }
                }
              },
              {
                "range": {
                  "minAge": {
                    "lte": age
                  }
                }
              }
            ]
          }
          },
          {
            "bool": {
              "must": {
                "range": {
                  "maxMonthlyFee": {
                    "lte": maxMonthlyFee
                  }
                }
              }
            }
          },
          {
            "bool": {
              "must": {
                "range": {
                  "maxWeeklyFee": {
                    "lte": maxWeeklyFee
                  }
                }
              }
            }
          } 
        ]
      }
    }
    if (this.availabilitySearch == "available" || this.availabilitySearch == "availableLater")
    {
      let availabilityQuery = {
        match: {
          availability: ""
        }
      }
      minimumShouldMatch++;
      if (this.availabilitySearch == "available")
        availabilityQuery.match.availability = "yesIHaveOpeningsThisMonth"
      else
        availabilityQuery.match.availability = "yesIWillHaveFutureOpenings"
      searchQuery.bool.should.push(availabilityQuery as any);
    }
    if (this.educationSearch.length > 0)
    {
      minimumShouldMatch++;
      let educationSearchQuery = generateMultipleSelectQuery(this.educationSearch, "education");
      searchQuery.bool.should.push(educationSearchQuery);
    }
    if (this.scheduleSearch.length > 0)
    {
      minimumShouldMatch++;
      let scheduleSearchQuery = generateMultipleSelectQuery(this.scheduleSearch, "schedule");
      searchQuery.bool.should.push(scheduleSearchQuery);
    }
    if (this.additionalInfoSearch.length > 0)
    {
      minimumShouldMatch++;
      let additionalInfoSearchQuery = generateMultipleSelectQuery(this.additionalInfoSearch, "additionalInfo");
      searchQuery.bool.should.push(additionalInfoSearchQuery);
    }
    if (this.accomodationsSearch.length > 0)
    {
      minimumShouldMatch++;
      let accomodationsSearchQuery = generateMultipleSelectQuery(this.accomodationsSearch, "accomodations");
      searchQuery.bool.should.push(accomodationsSearchQuery);
    }
    if (this.medicalSearch.length > 0)
    {
      minimumShouldMatch++;
      let medicalSearchQuery = generateMultipleSelectQuery(this.medicalSearch, "medical");
      searchQuery.bool.should.push(medicalSearchQuery);
    }
    if (this.languageSearch.length > 0)
    {
      minimumShouldMatch++;
      let languageSearchQuery = generateMultipleSelectQuery(this.languageSearch, "language");
      searchQuery.bool.should.push(languageSearchQuery);
    }
    if (this.financialAidSearch.length > 0)
    {
      minimumShouldMatch++;
      let financialAidSearchQuery = generateMultipleSelectQuery(this.financialAidSearch, "financialAid");
      searchQuery.bool.should.push(financialAidSearchQuery);
    }
    if (this.headStartSearch.length > 0)
    {
      minimumShouldMatch++;
      let headStartSearchQuery = generateMultipleSelectQuery(this.headStartSearch, "headStart");
      searchQuery.bool.should.push(headStartSearchQuery);
    }
    if (this.qualitySearch.length > 0)
    {
      minimumShouldMatch++;
      let qualitySearchQuery = {
        "bool": {
          "must": []
        }
      }
      for (let i = 0; i < this.qualitySearch.length; i++)
      {
        let matchQuery = {
          match: {}
        };
        matchQuery.match[this.qualitySearch[i]] = true;
        qualitySearchQuery.bool.must.push(matchQuery);
      }
      searchQuery.bool.should.push(qualitySearchQuery as any);
    }
    if (this.startTimeSearch != null && this.endTimeSearch != null)
    {
      minimumShouldMatch++;
      let timeSearch = {
        bool: {
          should: [
            {
              bool: {
                must: [
                  {
                    bool: {
                      must: {
                        range: {
                          startTimeHourMinute: {
                            lte: this.startTimeSearch.getHours() * 100 + this.startTimeSearch.getMinutes()
                          }
                        }
                      }
                    }
                  },
                  {
                    bool: {
                      must: {
                        range: {
                          endTimeHourMinute: {
                            gte: this.endTimeSearch.getHours() * 100 + this.endTimeSearch.getMinutes()
                          }
                        }
                      }
                    }
                  }
                ]
              }
            }
          ],
          minimum_should_match: 1
        }
      }
      searchQuery.bool.should.push(timeSearch as any);
    }
    searchQuery.bool["minimum_should_match"] = minimumShouldMatch;
    const postData = {
      Query: {
        // query: {
        //   query_string: {
        //     query: this.searchText
        //   }
        // }
        //sort: [
        //  {
        //    "networkName.keyword": "asc"
        //  },
        //  "_score"
        //],
        //from: 0,
        //size: 10,
        query: searchQuery
      },
      createResponse() {
        return {} as any;
      }
    };
    const response = await client.postToUrl<any>(url, postData);
    if (response.IsSuccess) {
      return response.Data.hits.hits.map(t => t._source);
    }
  }

  showHideFilters()
  {
    console.log("showhidefilters");
    if (document.querySelector('#showHideFilters').innerHTML == "Add Filters")
    {
      document.querySelector("#showHideFilters").innerHTML = "Hide Filters"
      let hiddens = document.getElementsByClassName('toggle-hidden');
      for (let i = 0; i < hiddens.length; i++)
        hiddens[i].removeAttribute('hidden');
    }
    else
    {
      document.querySelector("#showHideFilters").innerHTML = "Add Filters";
      let hiddens = document.getElementsByClassName('toggle-hidden');
      for (let i = 0; i < hiddens.length; i++)
        hiddens[i].setAttribute('hidden', 'hidden');
    }
  }

  resetFilters()
  {
    //this.optionList = [];
    console.log(this.selectedValue);
    this.selectedValue = [];
  }

  entered(enteredValue, indexName)
  {
    let tempArray = [];
    for (let i = 0; i < this.selectedValue.length; i++)
      tempArray.push(this.selectedValue[i]);
    this.optionList.push({"label": indexName + ': ' + enteredValue, "value": indexName + ': ' + enteredValue});
    tempArray.push(indexName + ': ' + enteredValue);
    this.selectedValue = [];
    this.selectedValue = tempArray;
  }
}

function generateMultipleSelectQuery(multipleSelected: string[], indexName: string) //requires that item has all the selected parameters
{
  let multipleSelectSearchQuery = {
    "bool": {
      "must": []
    }
  };
  for (let i = 0; i < multipleSelected.length; i++)
  {
    let matchQuery = {
      match: {}
    };
    matchQuery.match[indexName] = multipleSelected[i];
    multipleSelectSearchQuery.bool.must.push(matchQuery)
  }
  return multipleSelectSearchQuery;
}