export const getTicketMenuData: any[] = [
  {
    title: "Conversation",
    key: "tickets.all",
    url: "/${item.root}/ticket",
    icon: "fe fe-home"
  },
  {
    title: "Report",
    key: "tickets.report",
    url: "/${item.root}/report",
    icon: "fa fa-bar-chart-o"
  },
  {
    category: true, // render category
    title: "Admin", // category title
    key: "ticket.setting"
  },
  {
    title: "Views",
    key: "ticket.setting.view",
    url: "/${item.root}/setting/views",
    icon: "fa fa-tasks"
  },
  {
    title: "Templates",
    key: "ticket.setting.template",
    url: "/${item.root}/setting/templates",
    icon: "fa fa-file-code-o"
  },
  {
    title: "Fields",
    key: "ticket.setting.fields",
    url: "/${item.root}/setting/fields",
    icon: "fa fa-edit"
  },
  {
    title: "Setting",
    key: "ticket.setting.basic",
    url: "/${item.root}/setting/basic",
    icon: "fa fa-gear"
  },
  {
    category: true, // render category
    title: "User", // category title
    key: "ticket.users"
  },
  {
    title: "Parents",
    key: "ticket.user.parent",
    url: "/${item.root}/user/parent",
    icon: "fa fa-users"
  },
];
