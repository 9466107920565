import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  Actions,
  createEffect,
  Effect,
  ofType,
  OnInitEffects
} from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable, of, empty, from } from "rxjs";
import {
  map,
  switchMap,
  catchError,
  withLatestFrom,
  concatMap
} from "rxjs/operators";
// import store from "store";
import { NzNotificationService } from "ng-zorro-antd/notification";
import * as Reducers from "src/app/store/reducers";
import * as UserActions from "./actions";
import { AuthService } from "../../core/auth/auth.service";
import { AuthenticateResponse } from "src/app/core/auth/auth.model";
import { environment } from "src/environments/environment";

@Injectable()
export class UserEffects implements OnInitEffects {
  constructor(
    private actions: Actions,
    private authService: AuthService,
    // private gatewayService: GatewayAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private rxStore: Store<any>,
    private notification: NzNotificationService
  ) {}

  @Effect()
  login: Observable<any> = this.actions.pipe(
    ofType(UserActions.LOGIN),
    map((action: UserActions.Login) => action.payload),
    concatMap(action =>
      of(action).pipe(
        withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))
      )
    ),
    switchMap(([payload, settings]) => {
      let user: Promise<AuthenticateResponse>;
      user = this.authService.loginWithToken(payload.Token, payload.Provider);
      return from(user).pipe(
        map(response => {
          if (response && response.SessionId) {
            this.notification.success(
              "Logged In",
              "You have successfully logged in!"
            );
            return new UserActions.LoadCurrentAccount();
          }
          this.notification.warning(
            "Auth Failed",
            response.ResponseStatus.Message
          );
          return new UserActions.LoginUnsuccessful();
        }),
        catchError(error => {
          console.log("LOGIN ERROR: ", error);
          this.notification.error("Auth Failed", error.responseStatus.message);
          return from([{ type: UserActions.LOGIN_UNSUCCESSFUL }]);
        })
      );
    })
  );

  @Effect()
  loginBasic: Observable<any> = this.actions.pipe(
    ofType(UserActions.LOGIN_BASIC),
    map((action: UserActions.LoginBasic) => action.payload),
    concatMap(action =>
      of(action).pipe(
        withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))
      )
    ),
    switchMap(([payload, settings]) => {
      return from(
        this.authService.loginWithPassword(payload.UserName, payload.Password)
      ).pipe(
        map(response => {
          if (response && response.SessionId) {
            return new UserActions.LoadCurrentAccount();
          }
          this.notification.warning(
            "Auth Failed",
            response.ResponseStatus.Message
          );
          return new UserActions.LoginUnsuccessful();
        }),
        catchError(error => {
          console.log("LOGIN ERROR: ", error);
          this.notification.error("Auth Failed", error.responseStatus.message);
          return from([{ type: UserActions.LOGIN_UNSUCCESSFUL }]);
        })
      );
    })
  );

  loadCurrentAccount = createEffect(() =>
    this.actions.pipe(
      ofType(UserActions.LOAD_CURRENT_ACCOUNT),
      map((action: UserActions.LoadCurrentAccount) => true),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))
        )
      ),
      switchMap(([action, settings]) => {
        let user: Promise<AuthenticateResponse>;
        user = this.authService.currentAccount();
        return from(user).pipe(
          map(response => {
            if (
              response &&
              response.SessionId &&
              response.Permissions.length > 0
            ) {
              if (this.route.snapshot.queryParams.returnUrl) {
                this.router.navigateByUrl(
                  this.route.snapshot.queryParams.returnUrl
                ); // // redirect to returnUrl
              } else if (this.router.url.includes("/home")) {
                this.router.navigate(["/"]); // redirect to root route on auth pages
              }
              return new UserActions.LoadCurrentAccountSuccessful(response);
            }
            this.notification.error(
              "Error",
              "You have no permission to access this site!"
            );
            return new UserActions.LoadCurrentAccountUnsuccessful();
          }),
          catchError(error => {
            console.log("ACCOUNT LOAD ERROR: ", error);
            return from([{ type: UserActions.LOGIN_UNSUCCESSFUL }]);
          })
        );
      })
    )
  );

  @Effect()
  logout: Observable<any> = this.actions.pipe(
    ofType(UserActions.LOGOUT),
    map((action: UserActions.Logout) => true),
    concatMap(action =>
      of(action).pipe(
        withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))
      )
    ),
    switchMap(([, settings]) => {
      let user: Promise<AuthenticateResponse>;
      user = this.authService.logout();
      return from(user).pipe(
        map(() => {
          // const returnUrl = window.location.origin + "%2F%23%2F" ;
          // const sloUrl = environment.ccGateway.sloUrl + "?returnUrl=" + returnUrl;
          // window.location.href = sloUrl;
          this.router.navigate(["/home/login"]);
          return new UserActions.FlushUser();
        })
      );
    })
  );

  loadCurrentAccountUnsuccessful = createEffect(() =>
    this.actions.pipe(
      ofType(UserActions.LOGIN_UNSUCCESSFUL),
      map((action: UserActions.LoadCurrentAccountUnsuccessful) => true),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))
        )
      ),
      switchMap(([, settings]) => {
        let user: Promise<AuthenticateResponse>;
        // user = this.authService.logout();
        return from(user).pipe(
          map(() => {
            // this.router.navigate(["/home/403"]);
            return new UserActions.FlushUser();
          })
        );
      })
    )
  );

  ngrxOnInitEffects(): Action {
    return { type: UserActions.LOAD_CURRENT_ACCOUNT };
  }
}
