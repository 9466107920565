import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

// antd components module
import { AntdModule } from "src/app/antd.module";
import { DevExtremeModule } from "devextreme-angular";
import { NgxPermissionsModule } from "ngx-permissions";
import { AppWithPermissionDirective } from "./directives/app.permission";
import { NotifyService } from "./message/notify.service";
import { ConvertService } from "./utils/convert.service";
import { SafeContentHtmlPipe } from "./pipe/html";
import { SafeUrlPipe } from "./pipe/safe";
import { dxDataGridSerivceDirective } from "./devExtreme/dxDataGridDirective";
import { dxFormSerivceDirective } from "./devExtreme/dxFormDirective";

const MODULES = [
  CommonModule,
  RouterModule,
  NgxPermissionsModule,
  AntdModule,
  DevExtremeModule,
  TranslateModule
];

const declars = [
  AppWithPermissionDirective, 
  dxDataGridSerivceDirective,
  dxFormSerivceDirective,
  SafeContentHtmlPipe, 
  SafeUrlPipe];
@NgModule({
  imports: [...MODULES],
  declarations: [...declars],
  exports: [...MODULES, ...declars],
  providers: [NotifyService, ConvertService]
})
export class SharedModule {}
