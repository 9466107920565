import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";

import { LayoutAuthComponent } from "./Auth/auth.component";
import { LayoutMainComponent } from "./Main/main.component";
import { LayoutPublicComponent } from "./Public/public.component";
import { CoreComponentsModule } from "../components/components.module";

const COMPONENTS = [
  LayoutAuthComponent,
  LayoutMainComponent,
  LayoutPublicComponent
];

@NgModule({
  imports: [SharedModule, CoreComponentsModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS]
})
export class LayoutsModule {}
