import { BaseRequest, IReturn } from "src/app/core/models/common.api";
// [Route("/user/myprofile/Picture", "GET")]
export class GetUserPictureRequest extends BaseRequest implements IReturn<any> {
  public RequestUserId: number;
  createResponse() {
    return {};
  }
  getTypeName() {
    return "GetUserPictureRequest";
  }
}
