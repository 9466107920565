import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Observable, of } from "rxjs";

import { select, Store } from "@ngrx/store";
import * as Reducers from "src/app/store/reducers";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root"
})
export class CCAuthGuard implements CanActivate {
  authorized: boolean;
  constructor(private store: Store<any>, 
    public auth: AuthService,
    public router: Router) {
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.authorized = state.authorized;
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authorized) {
      return true;
    }
    this.login(state.url, next.queryParams.AccessCode);
    // this.router.navigate(["home/login"], {
    //   queryParams: { returnUrl: state.url }
    // });
    return false;
  }

  private login(returnUrl, token) {
    let url = returnUrl;
    if (token) {
      url = this.removeParam("AccessCode", returnUrl);
      this.router.navigate(["home/login"], {
        queryParams: {
          returnUrl: url,
          AccessCode: token
        }
      });
    } else {
      this.router.navigate(["home/login"], {
        queryParams: { returnUrl: returnUrl }
      });
    }
  }

  private removeParam(key, sourceURL) {
    let rtn = sourceURL.split("?")[0];
    let param;
    let params_arr = [];
    const queryString =
      sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
      params_arr = queryString.split("&");
      for (let i = params_arr.length - 1; i >= 0; i -= 1) {
        param = params_arr[i].split("=")[0];
        if (param === key) {
          params_arr.splice(i, 1);
        }
      }
      rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
  }
}
