<!-- <nz-alert
nzType="warning"
nzCloseable
nzMessage="We are testing the permissions feature, if you can't find the page you need, please Skype @Gordon."
(nzOnClose)="afterClose()"
></nz-alert> -->
<div
  [ngClass]="{
    vb__layout__grayBackground: settings.isGrayBackground
  }"
>
  <nz-layout
    [ngClass]="{
      vb__layout: true,
      vb__layout__contentMaxWidth: settings.isContentMaxWidth,
      vb__layout__appMaxWidth: settings.isAppMaxWidth,
      vb__layout__squaredBorders: settings.isSquaredBorders,
      vb__layout__cardsShadow: settings.isCardShadow,
      vb__layout__borderless: settings.isBorderless
    }"
  >
    <vb-variants></vb-variants>
    <!--    <vb-sidebar></vb-sidebar>-->
    <!--    <vb-support-chat></vb-support-chat>-->

    <!-- classic left menu -->
    <nz-sider
      *ngIf="
        settings.layoutMenu === 'classic' &&
        settings.menuLayoutType === 'left' &&
        !settings.isMobileView
      "
      [nzWidth]="settings.isMenuCollapsed ? 80 : settings.leftMenuWidth"
      style="transition: none !important"
    >
      <vb-menu-classic-left></vb-menu-classic-left>
    </nz-sider>
    <div *ngIf="settings.layoutMenu === 'classic' && settings.isMobileView">
      <div class="handler" (click)="toggleMobileMenu()">
        <div class="handlerIcon"></div>
      </div>
      <nz-drawer
        [nzClosable]="false"
        [nzWrapClassName]="'mobileMenu'"
        [nzVisible]="settings.isMobileMenuOpen"
        nzPlacement="left"
        (nzOnClose)="toggleMobileMenu()"
        [nzWidth]="settings.isMenuCollapsed ? 80 : settings.leftMenuWidth"
      >
        <ng-container *nzDrawerContent>
          <vb-menu-classic-left></vb-menu-classic-left>
        </ng-container>
      </nz-drawer>
    </div>
    <!-- <div *ngIf="settings.layoutMenu === 'classic' && settings.menuLayoutType === 'top' && !settings.isMobileView">
      <vb-menu-classic-top></vb-menu-classic-top>
    </div> -->
    <nz-layout>
      <nz-header
        *ngIf="settings.layoutTopbar === 'v1'"
        [ngClass]="{
          vb__layout__header: true,
          vb__layout__fixedHeader: settings.isTopbarFixed,
          vb__layout__headerGray: settings.isGrayTopbar,
          vb__layout__separatedHeader: settings.isTopbarSeparated
        }"
      >
        <vb-topbar></vb-topbar>
      </nz-header>
      <!-- <vb-breadcrumbs ></vb-breadcrumbs> -->

      <nz-content class="vb__layout__content">
        <nz-alert
          nzType="info"
          nzCloseable
          nzMessage="ITOPS 1 + ITOPS 2 => ITOPS 3"
          [nzDescription]="descInfo"
          nzShowIcon
        ></nz-alert>
        <div
          [@slideFadeinUp]="routeAnimation(outlet, 'slideFadeinUp')"
          [@slideFadeinRight]="routeAnimation(outlet, 'slideFadeinRight')"
          [@zoomFadein]="routeAnimation(outlet, 'zoomFadein')"
          [@fadein]="routeAnimation(outlet, 'fadein')"
        >
          <router-outlet #outlet="outlet"></router-outlet>
        </div>
      </nz-content>
      <vb-footer></vb-footer>
    </nz-layout>
  </nz-layout>
</div>
<ng-template #descInfo>
  <p>
    The new ITOPS3 experience is now available. We've redesigned the ITOPS to
    make it easier to use.
    <a href="https://itops3.carecloud.io" target="_blank"
      ><u>Try out the new version</u></a
    >. We are continuing to make improvements to the user experience based on
    your feedback, stay tuned!
  </p>
</ng-template>
