export const getMenuData: any[] = [
  {
    title: "Dashboard",
    key: "_dashboard",
    url: "/dashboard",
    icon: "fe fe-home"
  },
  {
    category: true, // render category
    title: "Products", // category title
    key: "_app"
  },
  {
    title: "CareWait 2",
    key: "app.carewait2",
    url: "/products/carewait",
    icon: "fe fe-folder",
    children: [
      {
        title: "Workspace",
        key: "app.carewait2.workspace",
        url: "/products/carewait/workspace"
      },
      {
        title: "Template",
        key: "app.carewait2.template",
        url: "/products/carewait/template",
      },
      {
        title: "Config",
        key: "app.carewait2.config",
        url: "/products/carewait/config/package",
      },
      {
        title: "Network",
        key: "app.carewait2.network",
        url: "/products/carewait/network",
        params: {
          tab: "network"
        }
      }
    ]
  },
  {
    title: "CareControl 4",
    key: "app.carecontrol",
    url: "/products/carecontrol",
    icon: "fe fe-folder",
    children: [
      {
        title: "Customer",
        key: "app.carecontrol.customer",
        url: "/products/carecontrol/customer/list"
      },
      {
        title: "Template",
        key: "app.carecontrol.template",
        url: "/products/carecontrol/template"
      },
      {
        title: "Config",
        key: "app.carecontrol.config",
        url: "/products/carecontrol/config/package",
      },
      {
        title: "Monitor",
        key: "app.carecontrol.monitor",
        url: "/products/carecontrol/monitor/job",
      },
    ]
  },
  {
    title: "CareConnect",
    key: "app.careconnect",
    url: "/products/careconnect",
    icon: "fe fe-folder",
    children: [
      {
        title: "Provider",
        key: "app.careconnect.provider",
        url: "/products/careconnect/provider"
      },
      {
        title: "Family",
        key: "app.careconnect.family",
        url: "/products/careconnect/family"
      },
      {
        title: "Form",
        key: "app.careconnect.form",
        url: "/products/careconnect/form"
      },
      {
        title: "Config",
        key: "app.careconnect.config",
        url: "/products/careconnect/config/package"
      },
      {
        title: "Monitor",
        key: "app.careconnect.monitor",
        url: "/products/careconnect/monitor"
      },
      {
        title: "Search Pofiles",
        key: "ab.seach",
        url: "/products/careconnect/search"
      },
      {
        title: "Provider License",
        key: "app.careconnect.license",
        url: "/products/careconnect/gov/childcarecenters"
      },
    ]
  },
  {
    title: "Funder Portal",
    key: "app.funderportal",
    icon: "fe fe-folder",
    children: [
      {
        title: "Workspace",
        key: "app.funderportal.workspace",
        url: "/products/funderportal/workspace",
        params: {
          tab: "workspace"
        }
      }
    ]
  },
  {
    title: "Agency Portal",
    key: "app.agencyportal",
    icon: "fe fe-folder",
    children: [
      {
        title: "Workspace",
        key: "app.agencyportal.workspace",
        url: "/products/agencyportal/workspace",
        params: {
          tab: "workspace"
        }
      }
    ]
  },
  {
    category: true, // render category
    title: "Platform", // category title
    key: "_cc"
  },
  {
    title: "Customers",
    key: "cc.customer",
    icon: "fa fa-institution",
    children: [
      {
        title: "All Customers",
        key: "cc.customer.all",
        url: "/customer"
      },
      // {
      //   title: "Subscription",
      //   key: "cc.subscription",
      //   url: "/subscription"
      // }
    ]
  },
  {
    title: "Users",
    key: "cc.user",
    icon: "fe fe-users",
    children: [
      {
        title: "All Users",
        key: "cc.user.list",
        url: "/user"
      },
      {
        title: "Security",
        key: "cc.user.security",
        url: "/user/security"
      }
    ]
  },
  // {
  //   title: "Ticket Portal",
  //   key: "app.ticketportal",
  //   icon: "fe fe-folder",
  //   children: [
  //     {
  //       title: "Channel",
  //       key: "app.ticketportal.channel",
  //       url: "/ticket/channel"
  //     }
  //   ]
  // },
  {
    title: "Usage",
    key: "cc.usage",
    icon: "fa fa-line-chart",
    children: [
      // {
      //   title: "Search",
      //   key: "cc.usage.search",
      //   url: "/usage/search"
      // },
      {
        title: "Alert",
        key: "cc.usage.alert",
        url: "/usage/alert"
      },
      {
        title: "Submission",
        key: "cc.usage.submission",
        url: "/usage/submission/customer"
      },
      {
        title: "Document",
        key: "cc.usage.document",
        url: "/usage/document"
      },
      {
        title: "Message",
        key: "cc.usage.message",
        url: "/usage/message/email",
      },
      {
        title: "Job",
        key: "cc.usage.job",
        url: "/usage/job",
        params: {
          tab: "dashboard"
        }
      },
      {
        title: "Search Usage",
        key: "cc.usage.searchusage",
        url: "/usage/searchusage",
        parmas: {
          tab: "dashboard"
        }
      },
      {
        title: "Report Server",
        key: "cc.usage.rs",
        url: "/usage/rs"
      },
      
    ]
  },

  {
    category: true,
    title: "APP Builder",
    key: "_ab"
  },
  {
    title: "App Forms",
    key: "ab.form",
    url: "/appbuilder/forms",
    icon: "fe fe-box",
    children: [
      {
        title: "CareCloud",
        key: "ab.form.carecloud",
        url: "/appbuilder/forms/carecloud",
        params: {
          tab: "itops2"
        }
      },
      {
        title: "CareControl",
        key: "ab.form.carecontrol",
        url: "/appbuilder/forms/carecontrol"
      }
    ]
  },
  {
    title: "App Flows",
    key: "ab.flow",
    url: "/appbuilder/flows",
    icon: "fe fe-box",
    children: [
      {
        title: "Settings",
        key: "ab.flow.settings",
        url: "/appbuilder/flows/setting"
      },
      {
        title: "Monitor",
        key: "ab.flow.monitor",
        url: "/appbuilder/flows/monitor"
      }
    ]
  },
  {
    category: true,
    key: "_admin",
    title: "ITOPS2 Admin"
  },
  {
    title: "Users and Groups",
    key: "admin.users",
    icon: "fe fe-user",
    url: "/admin/users"
  },
  {
    title: "Roles and Permisisons",
    key: "admin.roles",
    url: "/admin/settings",
    icon: "fe fe-user",
    permissions: ["ADMINISTRATOR"]
  },
  {
    title: "System",
    key: "admin.system",
    url: "/admin/system",
    icon: "fe fe-user"
  }
  // {
  //   title: "Report Builder",
  //   key: "ab.report",
  //   url: "/appbuilder/report",
  //   icon: "fa fa-bar-chart"
  // }
];
