import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { AuthenticateResponse } from "src/app/core/auth/auth.model";
import * as Reducers from "src/app/store/reducers";
import Talk from "talkjs";
import { TalkService } from "./service";

@Component({
  selector: "app-topbar-notifications",
  styleUrls: ["component.scss"],
  templateUrl: "component.html"
})
export class AppTopbarNotificationComponent implements OnInit {
  @ViewChild("talkjsContainer") talkjsContainer!: ElementRef;
  private inbox: Talk.Chatbox;
  private session: Talk.Session;
  badgeCount: number = 7;
  constructor(private store: Store<any>, private talkService: TalkService) {
    this.store
      .pipe(select(Reducers.getUser))
      .subscribe((u: AuthenticateResponse) => {
        if (u && u.Meta) {
          this.talkService.createCurrentSession(u).then(r => {
            this.createInbox();
          });
        }
      });
  }

  badgeCountIncrease() {
    this.badgeCount = this.badgeCount + 1;
  }
  ngOnInit() {}

  private async createInbox() {
    if (this.talkjsContainer) {
      this.inbox = await this.talkService.createInbox();
      this.inbox.mount(this.talkjsContainer.nativeElement);
      // this.talkService.destroyAllLoadedPopups();
    }
  }
}
